import React, { useLayoutEffect, useRef, useState } from "react"
import SpeechBubbleIcon from "../images/speech_bubble.svg"
import { ClientOnlyRenderable } from "./ClientOnlyRenderable"
import * as styles from "./SpeechBubble.module.scss"
import { maxSize, scaleSize, Size } from "../util/size"
import { mergeClassNames } from "../util/util"

const BASE_BUBBLE_SIZE: Size = {
  width: 571,
  height: 396
}

interface SpeechBubbleProps {
  className?: string,
  style?: React.CSSProperties,
  size?: Size,
  listenForUpdates?: boolean
}
interface SpeechBubbleState {
  contentWidth: number,
  contentHeight: number
}
export const SpeechBubble: React.FC<SpeechBubbleProps> = (props) => {
  return (
    <ClientOnlyRenderable serverSideRenderable={(
      <div className={props.className} style={{
        ...(props.size ?? {}),
        ...props.style
      }}>
        {props.children}
      </div>
    )}>
      <SpeechBubbleContent {...props}/>
    </ClientOnlyRenderable>
  )
}

const SpeechBubbleContent: React.FC<SpeechBubbleProps> = ({children, className, style, size, listenForUpdates = true}) => {
  const [state, setState] = useState<SpeechBubbleState | null>((size !== undefined) ? {
    contentWidth: scaleSize(BASE_BUBBLE_SIZE, size).width,
    contentHeight: scaleSize(BASE_BUBBLE_SIZE, size).height
  } : null)

  const contentRef = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    if (size !== undefined) {
      return
    }

    const el = contentRef.current
    if (el !== null) {
      const updateBoundingRect = () => {
        const rect = el.getBoundingClientRect()
        let width = rect.width
        let height = rect.height

        if (el.scrollHeight > height ) {
          height = el.scrollHeight
        }
        if (el.scrollWidth > width) {
          width = el.scrollWidth
        }

        setState({
          ...state,
          contentWidth: width,
          contentHeight: height
        })
      }


      if (listenForUpdates) {
        el.addEventListener('resize', () => {
          updateBoundingRect()
        })
      }
      updateBoundingRect()
    }
  }, [contentRef.current, size, listenForUpdates])

  const actualSize: Size | undefined = (state !== null) ? maxSize(
    scaleSize(
      BASE_BUBBLE_SIZE,
      {
        width: state.contentWidth,
        height: state.contentHeight
      }
    ),
    {
      width: 100,
      height: 72
    }
  ) : undefined

  return (
    <div className={mergeClassNames(styles.SpeechBubble, className)} style={{
      ...actualSize,
      ...(style ?? {})
    }}>
      <span ref={contentRef} className={styles.content}>{children}</span>
      <SpeechBubbleIcon
        className={styles.bubble}
        style={{
          width: (actualSize?.width ?? 0) + 40,
          height: (actualSize?.height ?? 0) + 20
        }}
      />
    </div>
  )
}

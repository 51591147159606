import React from "react"
import { graphql, Link } from "gatsby"
import { IStrapiImage } from "../../model/IStrapiImage"
import Section from "./Section"
import * as styles from "./OurStorySection.module.scss"
import { FramedPicture } from "../FramedPicture"
import { StaticImage } from "gatsby-plugin-image"
import { RichText } from "../RichText"
import { getPathForLocale } from "../../util/util"
import { useAppContext } from "../AppContext"

interface OurStoryProps {
  ourStoryHeader: string,
  ourStorySubHeader1: string,
  ourStorySubHeader2: string,
  ourStorySubHeader3: string,
  ourStoryBody: string,
  ourStoryImage: IStrapiImage
}
export const OurStorySection: React.FC<OurStoryProps> = ({ourStoryHeader, ourStorySubHeader1, ourStorySubHeader3, ourStorySubHeader2, ourStoryBody, ourStoryImage}) => {
  const { currentLocale } = useAppContext()

  return (
    <>
      <Section className={styles.ourStoryContainer}>
        <div className={styles.ourStoryHeaderBody}>
          <div className={styles.sidebar}>
            <h1 className={styles.ourStoryHeader}>{ourStoryHeader}</h1>
            <h2 className={styles.ourStorySubHeader1}>{ourStorySubHeader1}</h2>
            <div className={styles.ourStorySubHeader2}>{ourStorySubHeader2}</div>
            <div className={styles.ourStorySubHeader3}>{ourStorySubHeader3}</div>
          </div>
          <div className={styles.content}>
            <div className={styles.ourStoryCircleImage}>
              <StaticImage src="../../images/sushi_badge.png" alt=""/>
            </div>
            <div className={styles.ourStoryImage}>
              <FramedPicture picture={ourStoryImage}/>
            </div>
          </div>
        </div>
        <div className={styles.ourStoryBody}>
          <RichText text={ourStoryBody} />
          <div className={styles.bottomLink}>Visit <a href="https://kindo.co.nz/about-the-growth-collective/" target="_self">kindo.co.nz</a> to find out more about what we do.</div>
        </div>
      </Section>

      <Section className={styles.overviewSection}>
        <div className={styles.findOutText}>Find out how we can help you:</div>
        <div className={styles.itemsContainer}>
          <Link to={getPathForLocale(currentLocale, "/families")}>
            <StaticImage alt="Families" src="../../images/families_icon_shadow.png" width={230} placeholder="blurred"/>
          </Link>
          <Link to={getPathForLocale(currentLocale, "/schools")}>
            <StaticImage alt="Schools" src="../../images/schools_icon_shadow.png" quality={100} width={230} placeholder="blurred"/>
          </Link>
          <Link to={getPathForLocale(currentLocale, "/cafes-caterers")}>
            <StaticImage alt="Cafes" src="../../images/cafes_icon_shadow.png" quality={100} width={230} placeholder="blurred"/>
          </Link>
        </div>
      </Section>
    </>
  )
}

export const ourStoryFragment = graphql`
  fragment ourStoryFragment on Strapi_ComponentSectionOurStory {
    ourStoryHeader
    ourStorySubHeader1
    ourStorySubHeader2
    ourStorySubHeader3
    ourStoryBody
    ourStoryImage {
      id
      url
      alternativeText
      urlSharp {
        childImageSharp {
          gatsbyImageData(width: 200, quality: 100)
        }
      }
    }
  }
`
import React, { useCallback, useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import { IStrapiImage } from "../../../model/IStrapiImage"
import * as styles from "./ShowSchoolMenuSection.module.scss"
import Section from "../Section"
import { GatsbyImage } from "gatsby-plugin-image"
import { IPageButton, PageButton } from "../../PageButton"
import { SchoolSearchBox } from "./SchoolSearchBox"
import ResizeObserver from "resize-observer-polyfill"

interface ShowSchoolMenuSectionProps {
  cloudTextHighlight: string,
  cloudTextMain: string,
  schoolMenuHeader: string,
  schoolMenuDescription1: string,
  schoolMenuDescription2: string,
  schoolMenuDescription3: string,
  schoolMenuColour: string,
  showSchoolMenuImage: IStrapiImage,
  schoolMenuButtons: IPageButton[]
}
export const ShowSchoolMenuSection: React.FC<ShowSchoolMenuSectionProps> = ({cloudTextHighlight, cloudTextMain, showSchoolMenuImage, schoolMenuHeader, schoolMenuDescription1, schoolMenuDescription2, schoolMenuDescription3, schoolMenuColour, schoolMenuButtons}) => {
  const BASE_IMAGE_WIDTH = 520
  const BASE_TEXT_TOP = 100
  const BASE_TEXT_LEFT = 53
  const [scale, setScale] = useState<number>(1)

  const imgRef = useRef<HTMLImageElement | null>(null)

  const onImageResize = useCallback((entries: ResizeObserverEntry[]) => {
    const imgEntry = entries[0]

    setScale(imgEntry.contentRect.width / BASE_IMAGE_WIDTH)
  }, [imgRef.current])

  useEffect(() => {
    const imgEl = imgRef.current
    if (imgEl !== null) {
      console.log("Binding ResizeObserver...")
      const observer = new ResizeObserver(onImageResize)
      observer.observe(imgEl)

      return () => observer.disconnect()
    }
  }, [imgRef.current])

  return (
    <Section>
      <div id="atMy" className={styles.showSchoolMenuSectionContainer}>
        <div className={styles.sidebar}>
          <div ref={imgRef} style={{maxWidth: BASE_IMAGE_WIDTH}}>
            <GatsbyImage image={showSchoolMenuImage.urlSharp.childImageSharp.gatsbyImageData} alt={showSchoolMenuImage.alternativeText}/>
          </div>
          <div
            style={{
              transform: `scale(${scale}, ${scale})`,
              transformOrigin: 'top left',
              top: BASE_TEXT_TOP * scale,
              left: BASE_TEXT_LEFT * scale,
              position: 'absolute'
            }}
          >
            <div className={styles.sidebarHighlight}>{cloudTextHighlight}</div>
            <div className={styles.sidebarMain}>{cloudTextMain}</div>
          </div>
        </div>
        <div className={styles.content}>
          <div style={{ color: schoolMenuColour}} className={styles.schoolMenuHeader}>{schoolMenuHeader}</div>
          <div className={styles.schoolMenuDescription1}>{schoolMenuDescription1}</div>
          <div className={styles.schoolMenuDescription2}>{schoolMenuDescription2}</div>
          <div className={styles.schoolMenuDescription3}>{schoolMenuDescription3}</div>
          <SchoolSearchBox highlightColour={schoolMenuColour}/>
          <div className={styles.schoolMenuButton}>
            {schoolMenuButtons.map((button, index) => (
              <PageButton key={`${index}-${button.id}`} {...button}/>
            ))}
          </div>
        </div>
      </div>
    </Section>
  )
}

export const twoColumnTalkSectionFragment = graphql`
  fragment showSchoolMenuSectionFragment on Strapi_ComponentSectionShowSchoolMenu {
    cloudTextHighlight
    cloudTextMain
    schoolMenuHeader
    schoolMenuDescription1
    schoolMenuDescription2
    schoolMenuDescription3
    schoolMenuColour
    schoolMenuButtons {
      id
      page {
        slug
      }
      type
      text
    }
    showSchoolMenuImage {
      id
      url
      alternativeText
      urlSharp {
        staticURL
        childImageSharp {
          gatsbyImageData(width: 520, quality: 100)
        }
      }
    }
  }
`
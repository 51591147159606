// extracted by mini-css-extract-plugin
export var howItWorksSectionContainer = "HowItWorksSection-module--howItWorksSectionContainer--2A8jX";
export var howItWorksHeader = "HowItWorksSection-module--howItWorksHeader--2crXA";
export var speechBubble = "HowItWorksSection-module--speechBubble--3TUNM";
export var bubbleContent = "HowItWorksSection-module--bubbleContent--2LxRh";
export var stepNumber = "HowItWorksSection-module--stepNumber--2oJr0";
export var stepBold = "HowItWorksSection-module--stepBold--_afmW";
export var stepText = "HowItWorksSection-module--stepText--3WxMd";
export var left = "HowItWorksSection-module--left--39GFk";
export var content = "HowItWorksSection-module--content--3kbJm";
export var contentImage = "HowItWorksSection-module--contentImage--2Jnyg";
export var localMenuBackground = "HowItWorksSection-module--localMenuBackground--31Tga";
export var twoRowCardContainer = "HowItWorksSection-module--twoRowCardContainer--1sslv";
export var right = "HowItWorksSection-module--right--3OOPm";
export var headerImg = "HowItWorksSection-module--headerImg--1UucT";
export var icon = "HowItWorksSection-module--icon--37bCZ";
export var description = "HowItWorksSection-module--description--SM1ND";
export var localMenuImageContainer = "HowItWorksSection-module--localMenuImageContainer--2R9Gq";
export var localMenuHeader = "HowItWorksSection-module--localMenuHeader--2IA4l";
export var localMenuDescription = "HowItWorksSection-module--localMenuDescription---TWNJ";
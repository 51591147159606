import React from "react"
import { RichText } from "./RichText"
import { IPageButton, PageButton } from "./PageButton"
import { mergeClassNames } from "../util/util"
import * as styles from "./SectionHeadingBodyButtonsColumn.module.scss"

interface SectionHeadingBodyButtonsColumnProps {
  heading: string,
  body: string,
  actionButtons: IPageButton[],
  className?: string
}
export const SectionHeadingBodyButtonsColumn: React.FC<SectionHeadingBodyButtonsColumnProps> = ({heading, body, actionButtons, className}) => {
  return (
    <div className={mergeClassNames(styles.SectionHeadingBodyButtonsColumn, className)}>
      <h2 className={styles.heading}>{heading}</h2>
      <RichText className={styles.body} text={body} />

      <div className={styles.actionButtons}>
          {actionButtons.map((button, index) => (
              <PageButton key={`${index}-${button.id}`} {...button}/>
      ))}
      </div>
    </div>
  )
}
// extracted by mini-css-extract-plugin
export var threeColumnCaseShareSection = "ThreeColumnCaseShareSection-module--threeColumnCaseShareSection--11Xnw";
export var left = "ThreeColumnCaseShareSection-module--left--3CUkw";
export var middle = "ThreeColumnCaseShareSection-module--middle--fRi3D";
export var right = "ThreeColumnCaseShareSection-module--right--3nCNx";
export var findOutBtn = "ThreeColumnCaseShareSection-module--findOutBtn--2OAOt";
export var socialSection = "ThreeColumnCaseShareSection-module--socialSection--1Yz0N";
export var threeColumnHeader = "ThreeColumnCaseShareSection-module--threeColumnHeader--2A0kC";
export var threeColumnSubHeader = "ThreeColumnCaseShareSection-module--threeColumnSubHeader--319_U";
export var speechBubble = "ThreeColumnCaseShareSection-module--speechBubble--3QtS0";
export var bubbleContent = "ThreeColumnCaseShareSection-module--bubbleContent--3qkgA";
export var bubbleContentHighlight = "ThreeColumnCaseShareSection-module--bubbleContentHighlight--WKcvy";
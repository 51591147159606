import React from "react"
import Layout from "./layout/Layout"
import { graphql } from "gatsby"
import SEO from "./seo_analytics/SEO"
import { IMetadata } from "../model/IMetadata"
import { AppContext, AppContextProvider } from "./AppContext"
import { SectionMapper, SectionProps } from "./section/SectionMapper"
import { SocialIconsSidebar } from "./social/SocialIconsSidebar"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from './Page.module.scss'

interface PageProps {
  data: {
    strapi: {
      pages: {
        locale: string,
        slug: string,
        metadata: IMetadata,
        content: SectionProps[]
      }[]
    }
  }
}
const page: React.FC<PageProps> = (props) => {
  const strapi = props.data.strapi

  if (!strapi || strapi.pages.length !== 1) {
    throw new Error("Failed to load exactly one page with the same locale and id.")
  }
  const {metadata, locale, slug, content} = strapi.pages[0]

  const appContext: AppContext = {
    currentLocale: locale,
    currentSlug: slug
  }

  return (
    <AppContextProvider context={appContext}>
      {/* USE FOR NOTICE */}
      {/* <div style={{ background: 'red', width: '100%', padding: '10px', color: 'white', textAlign: 'center'}}>NOTICE: Auckland, Northland and Bay of Plenty ezlunch services are cancelled for Tuesday. Pre-orders in those areas have been cancelled. Go to <a style={{ color: 'orange'}} href="https://shop.tgcl.co.nz/shop/my_orders.shtml">My Orders</a> to view.</div> */}
      <Layout pageSeo={metadata}>
        <SEO {...metadata}/>
        <SocialIconsSidebar pageSeo={metadata}/>

        { appContext.currentSlug == 'cafes-caterers' ?
          <StaticImage className={styles.hiddenXs} style={{ position: "fixed", right: 0, zIndex: 2, bottom: "140px"}} alt="" src="../images/yoghurt_angle.png" quality={100} width={100} placeholder="blurred"/>
        : undefined
        }
        { appContext.currentSlug == '' ?
          <StaticImage className={styles.hiddenXs} style={{ position: "fixed", left: 0, zIndex: 2, bottom: "140px"}} alt="" src="../images/avocado_side_transp.png" quality={100} width={100} placeholder="blurred"/>
        : undefined
        }
        { appContext.currentSlug == 'families' ?
          <StaticImage className={styles.hiddenXs} style={{ position: "fixed", right: 0, zIndex: 2, bottom: "140px"}} alt="" src="../images/strawberry_angle.png" quality={100} width={100} placeholder="blurred"/>
        : undefined
        }
        { appContext.currentSlug == 'schools' ?
          <StaticImage className={styles.hiddenXs} style={{ position: "fixed", left: 0, zIndex: 2, bottom: "140px"}} alt="" src="../images/avocado_side_transp.png" quality={100} width={100} placeholder="blurred"/>
        : undefined
        }
        {content.map((content, index) => (
          <SectionMapper section={content} key={`Page-Section-${content.id}-${index}`}/>
        ))}
      </Layout>
    </AppContextProvider>
  )
}
export default page

export const query = graphql`
  query($pageId: ID!, $locale: String!) {
    strapi {
      pages(where: {id: $pageId, _locale: $locale}) {
        id
        locale
        slug
        metadata {
          ...metadataFields
        }
        content {
          ...pageContentFragment
        }
      }
    }
  }
`

export const pageContentFragment = graphql`
  fragment pageContentFragment on Strapi_PageContentDynamicZone {
    typeName: __typename

    ... on Strapi_ComponentSectionTestimonials {
      id
      ...testimonialsSectionFragment
    }
    ... on Strapi_ComponentSectionLogoSlideshow {
      id
    }
    ... on Strapi_ComponentSectionFamiliesSchoolsCafesStatisticsOverview {
      id
      ...familiesSchoolsCafesStatisticsOverviewFragment
    }
    ... on Strapi_ComponentSectionTwoColumnPitch {
      id
      ...twoColumnPitchSectionFragment
    }
    ... on Strapi_ComponentSectionTwoPicturesAndBody {
      id
      ...picturesAndBodyQueryFragment
    }
    ... on Strapi_ComponentSectionFourColumnCard {
      id
      ...fourColumnSectionFragment
    }
    ... on Strapi_ComponentSectionQuestionsAndAnswers {
      id
      ...questionsAndAnswersSectionFragment
    }
    ... on Strapi_ComponentSectionTwoColumnTalk {
      id
      ...twoColumnTalkSectionFragment
    }
    ... on Strapi_ComponentSectionContactUs {
      typeName: __typename
      id
      ...contactUsSectionFragment
    }
    ... on Strapi_ComponentSectionShowSchoolMenu {
      typeName: __typename
      id
      ...showSchoolMenuSectionFragment
    }
    ... on Strapi_ComponentSectionHowItWorks {
      typeName: __typename
      id
      ...howItWorksSectionFragment
    }
    ... on Strapi_ComponentSectionHomeHeader {
      typeName: __typename
      id
      ...homepageHeaderSectionFragment
    }
    ... on Strapi_ComponentSectionOurStory {
      typeName: __typename
      id
      ...ourStoryFragment
    }
    ... on Strapi_ComponentSectionRightPictureAndBody {
      id
      ...rightPictureAndBodySectionFragment
    }
    ... on Strapi_ComponentSectionThreeColumnCaseShare {
      typeName: __typename
      id
      ...threeColumnCaseShareSectionFragment
    }
    ... on Strapi_ComponentSectionNews {
      typeName: __typename
      id
      ...newsSectionFragment
    }
    ... on Strapi_ComponentSectionFamiliesSchoolsCafesHeader {
      typeName: __typename
      id
      ...familiesSchoolsCafesHeaderSectionFragment
    }
  }
`

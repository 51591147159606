import React from "react"
import { RaisedButton } from "./button/RaisedButton"
import { graphql, navigate } from "gatsby"
import { getPathForLocale, triggerGtagEvent } from "../util/util"
import { useAppContext } from "./AppContext"
import { FlatButton } from "./button/FlatButton"
import { MyKindoButton } from "./button/MyKindoButton"
import { EMAIL_SCHOOL_SIGNUP_PITCH, MYKINDO_ATMY_URL, MYKINDO_JOIN_URL, MYKINDO_LOGIN_URL } from "../config"
import { navigate as reachNavigate } from '@reach/router';

enum PageButtonType {
  Plain = "plain",
  Raised = "raised",
  MyKindoLogin = "mykindo_login",
  MyKindoJoin = "mykindo_join",
  MyKindoAtmy = "mykindo_atmy",
  EmailSchoolSignupPitch = "email_school_signup_pitch",
  HowItWorksSection = "how_it_works",
  AtMySection = "at_my"
}

export interface IPageButton {
  id: string,
  page: {
    slug: string | null
  } | null,
  text: string,
  type: PageButtonType
}
export const PageButton: React.FC<IPageButton> = ({text, type, page}) => {
  const {currentLocale} = useAppContext()

  const pageSlug = (page === null) ? "404" : (page.slug ?? "")

  switch (type) {
    case PageButtonType.Plain:
      return <FlatButton onClick={() => navigate(getPathForLocale(currentLocale, pageSlug))}>{text}</FlatButton>
    case PageButtonType.Raised:
      return <RaisedButton onClick={() => navigate(getPathForLocale(currentLocale, pageSlug))}>{text}</RaisedButton>
    case PageButtonType.MyKindoLogin:
      return <MyKindoButton onClick={() => {
        triggerGtagEvent({
          action: "click",
          label: "mykindo_login"
        })
        window.open(MYKINDO_LOGIN_URL, "_self")
      }}>{text}</MyKindoButton>
    case PageButtonType.MyKindoJoin:
      return <MyKindoButton onClick={() => {
        triggerGtagEvent({
          action: "click",
          label: "mykindo_join"
        })
        window.open(MYKINDO_JOIN_URL, "_self")
      }}>{text}</MyKindoButton>
    case PageButtonType.MyKindoAtmy:
      return <FlatButton onClick={() => {
        triggerGtagEvent({
          action: "click",
          label: "mykindo_atmy"
        })
        window.open(MYKINDO_ATMY_URL, "_self")
      }}>{text}</FlatButton>
    case PageButtonType.EmailSchoolSignupPitch:
      return <FlatButton onClick={() => {
        triggerGtagEvent({
          action: "click",
          label: "email_school_signup_pitch"
        })
        window.open(EMAIL_SCHOOL_SIGNUP_PITCH, "_self")
      }}>{text}</FlatButton>
    case PageButtonType.HowItWorksSection:
      return <FlatButton onClick={() => reachNavigate(getPathForLocale(currentLocale, pageSlug + "#howItWorks"))}>{text}</FlatButton>
    case PageButtonType.AtMySection:
      return <FlatButton onClick={() => reachNavigate(getPathForLocale(currentLocale, pageSlug + "#atMy"))}>{text}</FlatButton>
    default:
      return <FlatButton onClick={() => navigate(getPathForLocale(currentLocale, pageSlug))}>{text}</FlatButton>
  }
}

export const pageButtonFragment = graphql`
  fragment pageButtonFragment on Strapi_ComponentSubcomponentsPageButton {
    id
    page {
      slug
    }
    type
    text
  }
`
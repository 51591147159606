import { GatsbyImage } from "gatsby-plugin-image"
import { IStrapiImage } from "../model/IStrapiImage"
import React from "react"
import * as styles from "./FramedPicture.module.scss"
import { mergeClassNames } from "../util/util"

interface FramedPictureProps {
  picture: IStrapiImage,
  className?: string
}
export const FramedPicture: React.FC<FramedPictureProps> = ({picture, className}) => {
  return (
    <GatsbyImage
      alt={picture.alternativeText}
      image={picture.urlSharp.childImageSharp.gatsbyImageData}
      className={mergeClassNames(styles.FramedPicture, className)}
    />
  )
}
// extracted by mini-css-extract-plugin
export var headingSection = "FourColumnSection-module--headingSection--2sIPU";
export var columnHeader = "FourColumnSection-module--columnHeader--gshTW";
export var columnImage = "FourColumnSection-module--columnImage--3eLBI";
export var columnPlus = "FourColumnSection-module--columnPlus--2QdSD";
export var headingSectionHeaderText = "FourColumnSection-module--headingSectionHeaderText--2w-GD";
export var fourColumnHeadingSmallText = "FourColumnSection-module--fourColumnHeadingSmallText--2zCn2";
export var headingSectionSubHeadingText = "FourColumnSection-module--headingSectionSubHeadingText--A3hpa";
export var fourColumnButton = "FourColumnSection-module--fourColumnButton--2LPx3";
export var cardSection = "FourColumnSection-module--cardSection--3KlEX";
export var cardColumn = "FourColumnSection-module--cardColumn--1QcPD";
export var cardBox = "FourColumnSection-module--cardBox--3ODb_";
export var cardHeading = "FourColumnSection-module--cardHeading--nnRSM";
export var cardDescription = "FourColumnSection-module--cardDescription--15Brr";
export var cardSmallText = "FourColumnSection-module--cardSmallText--2a35e";
export var cardContainer = "FourColumnSection-module--cardContainer--1JOWb";
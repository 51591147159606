// extracted by mini-css-extract-plugin
export var contactUsSection = "ContactUsSection-module--contactUsSection--26-Xi";
export var contactHeader = "ContactUsSection-module--contactHeader--2AANE";
export var contactTitle = "ContactUsSection-module--contactTitle--IfwDw";
export var contactDescription = "ContactUsSection-module--contactDescription--2TlJW";
export var contactValue = "ContactUsSection-module--contactValue--W7CPj";
export var contactRequired = "ContactUsSection-module--contactRequired--CqHja";
export var contactForm = "ContactUsSection-module--contactForm--xJdz8";
export var contactIcon = "ContactUsSection-module--contactIcon--1YEhY";
export var icon = "ContactUsSection-module--icon--2DXdx";
export var separator = "ContactUsSection-module--separator--7pVrV";
import React from "react"
import { mergeClassNames } from "../../util/util"
import * as styles from "./FlatButton.module.scss"

interface FlatButtonProps {
  onClick: VoidFunction,
  className?: string,
  style?: React.CSSProperties
}
export const FlatButton: React.FC<FlatButtonProps> = ({children, onClick, className, style}) => {
  return (
    <button
      className={mergeClassNames(styles.FlatButton, className)}
      onClick={onClick}
      style={style}
    >{children}</button>
  )
}
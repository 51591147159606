// extracted by mini-css-extract-plugin
export var overviewSection = "OurStorySection-module--overviewSection--19kVO";
export var itemsContainer = "OurStorySection-module--itemsContainer--1X18b";
export var ourStoryContainer = "OurStorySection-module--ourStoryContainer--2dqHr";
export var ourStoryHeader = "OurStorySection-module--ourStoryHeader--J7_Q1";
export var ourStoryBody = "OurStorySection-module--ourStoryBody--16x9G";
export var ourStorySubHeader1 = "OurStorySection-module--ourStorySubHeader1--2OTZ4";
export var ourStorySubHeader2 = "OurStorySection-module--ourStorySubHeader2--3eBfv";
export var ourStorySubHeader3 = "OurStorySection-module--ourStorySubHeader3--1d1sK";
export var ourStoryHeaderBody = "OurStorySection-module--ourStoryHeaderBody--2xC2V";
export var ourStoryImage = "OurStorySection-module--ourStoryImage--3GTa3";
export var ourStoryCircleImage = "OurStorySection-module--ourStoryCircleImage--1pF9V";
export var bottomLink = "OurStorySection-module--bottomLink--3fewU";
export var sidebar = "OurStorySection-module--sidebar--epaQh";
export var content = "OurStorySection-module--content--2JM0Z";
export var findOutText = "OurStorySection-module--findOutText--34UEW";
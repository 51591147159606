// extracted by mini-css-extract-plugin
export var TwoPicturesAndBody = "PicturesAndBody-module--TwoPicturesAndBody--2Oevu";
export var pictures = "PicturesAndBody-module--pictures--2G5E7";
export var picturesContent = "PicturesAndBody-module--picturesContent--1Qax1";
export var backImage = "PicturesAndBody-module--backImage--31LKR";
export var frontImage = "PicturesAndBody-module--frontImage--3775S";
export var badge = "PicturesAndBody-module--badge--3dUFg";
export var testimonial = "PicturesAndBody-module--testimonial--2r1hf";
export var testimonialHeading = "PicturesAndBody-module--testimonialHeading--1hUMN";
export var testimonialBody = "PicturesAndBody-module--testimonialBody--3Sa4i";
export var testimonialSource = "PicturesAndBody-module--testimonialSource--24hSh";
export var picturesWithMiddlePicture = "PicturesAndBody-module--picturesWithMiddlePicture--3T-2W";
export var middleImage = "PicturesAndBody-module--middleImage--YMo-P";
export var content = "PicturesAndBody-module--content--31B90";
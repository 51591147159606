import React from "react"
import Section from "./Section"
import { graphql, Link, useStaticQuery } from "gatsby"
import { IStrapiImage } from "../../model/IStrapiImage"
import * as styles from "./NewsSection.module.scss"
import { RichText } from "../RichText"
import { GatsbyImage } from "gatsby-plugin-image"
import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"
import Slider from "react-slick"
import { format, parseISO } from "date-fns"

interface NewsBlog {
  blogTitle: string,
  blogContent: string,
  slug: string,
  id: string,
  published_at: string,
  blogImage: IStrapiImage,
  locale: string
}

interface NewsData {
  strapi: {
    blogs: NewsBlog[]
  }
}

export interface NewsProps {
  newsHeader: string
}


export const NewsSection: React.FC<NewsProps> = ({newsHeader}) => {
  const data = useStaticQuery<NewsData>(query)
  const blogs = data.strapi.blogs
  const latestOne = blogs.slice(0,1)[0]
  const latestTwo = blogs.slice(1,2)[0]

  const sliderSettings =  {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    draggable: true,
    swipe: true,
    responsive: [{
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1026,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }]
  };

  return (
    <>
      <Section className={styles.SectionNews}>
        <h1 className={styles.newsHeader}>{newsHeader}</h1>
        <LatestBlogPost className={styles.latestOne} blog={latestOne} imageSide="right"/>
      </Section>
      <Section className={styles.SectionNews}>
        <LatestBlogPost className={styles.latestTwo} blog={latestTwo} imageSide="left"/>
      </Section>
      <div className={styles.blogsContainer}>
        <h2 className={styles.blogsHeader}>Previous posts</h2>
        <Slider { ...sliderSettings }>
          {blogs
          // .sort((a, b) => b.id - a.id)
          .map((blog, index) => (
            <div className={styles.sliderContent} key={index}>
              <h3 className={styles.blogTitle}>{blog.blogTitle}</h3>
              <RichText className={styles.blogContent} text={blog.blogContent} />
              <Link className={styles.readMore} to={`/news/${blog.slug}`} >
                Read more
              </Link>
              <GatsbyImage className={styles.blogImage} alt={blog.blogImage.alternativeText} image={blog.blogImage.urlSharp.childImageSharp.gatsbyImageData} />
            </div>
          ))}
        </Slider>
      </div>
    </>
  )
}

interface LatestBlogPostProps {
  className?: string,
  blog: NewsBlog,
  imageSide: "left" | "right"
}
const LatestBlogPost: React.FC<LatestBlogPostProps> = ({className, blog, imageSide}) => {
  const text = (
      <div className={(imageSide === "right") ? styles.sidebar : styles.content}>
        <div className={styles.blogHeading}>
          <h2 className={styles.blogTitle}>{blog.blogTitle}</h2>
          <span className={styles.blogDate}>{format(parseISO(blog.published_at), "dd MMMM, yyyy")}</span>
        </div>
        <RichText className={styles.blogContent} text={blog.blogContent} />
        <Link className={styles.readMore} to={`/news/${blog.slug}`} >
          Read more
        </Link>
      </div>
  )

  const image = (
      <div className={(imageSide === "right") ? styles.content : styles.sidebar}>
        <GatsbyImage className={styles.blogImage} alt={blog.blogImage.alternativeText} image={blog.blogImage.urlSharp.childImageSharp.gatsbyImageData} />
      </div>
  )

  return (
    <div className={className}>
      {(imageSide === "left") ? (
          <>
            {image}
            {text}
          </>
      ) : (
          <>
            {text}
            {image}
          </>
      )}
    </div>
  )
}

const query = graphql`
query {
    strapi {
      blogs(sort: "id:desc") {
        blogTitle
        blogContent
        id
        slug
        published_at
        locale
        blogImage {
          url
          alternativeText
          urlSharp {
            childImageSharp {
              gatsbyImageData(width: 300, quality: 100)
            }
          }
        }
      }
    }
  }
`

export const twoColumnTalkSectionFragment = graphql`
  fragment newsSectionFragment on Strapi_ComponentSectionNews {
    newsHeader
  }
`
import React, { MutableRefObject, useLayoutEffect, useRef, useState } from "react"
import { ClientOnlyRenderable } from "./ClientOnlyRenderable"

interface ScaleToWindowWidthProps {
  children: (
    ref: MutableRefObject<HTMLDivElement | null>,
    scaleX: number
  ) => React.ReactNode
}
export const ScaleToWindowWidth: React.FC<ScaleToWindowWidthProps> = (props) => {
  const dummyRef = useRef<HTMLDivElement | null>(null)

  return (
    <ClientOnlyRenderable serverSideRenderable={props.children(dummyRef, 1)}>
      <ScaleToWindowWidthImpl {...props}/>
    </ClientOnlyRenderable>
  )
}

interface ScaleToWindowWidthImplState {
  scale: number
  lastUpdate: Date | null,
  consecutiveUpdateCount: number
}
const ScaleToWindowWidthImpl: React.FC<ScaleToWindowWidthProps> = ({children}) => {
  const [state, setState] = useState<ScaleToWindowWidthImplState>({
    scale: 1,
    lastUpdate: null,
    consecutiveUpdateCount: 0
  })

  const ref = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    const el = ref.current
    if (el !== null) {
      const doScale = () => {
        const scale = state.scale

        const bounds = el.getBoundingClientRect()
        const unscaledWidth = bounds.width / scale

        const newScale = (unscaledWidth >= window.innerWidth) ? window.innerWidth / unscaledWidth : 1

        const timeSinceLastUpdate = (state.lastUpdate !== null) ? new Date().getTime() - state.lastUpdate!.getTime() : Infinity
        if (newScale !== scale && (state.consecutiveUpdateCount < 20 || timeSinceLastUpdate > 1200)) {
          setState({
            scale: newScale,
            lastUpdate: new Date(),
            consecutiveUpdateCount: (timeSinceLastUpdate > 1200) ? 1 : state.consecutiveUpdateCount + 1
          })

          if (localStorage.getItem("__kindo_flag_log_ScaleToWindowWidth_updates") === "true") {
            console.log(`Scale: ${scale}. New Scale: ${newScale}`)
          }
        } else {
          if (localStorage.getItem("__kindo_flag_log_ScaleToWindowWidth_updates") === "true") {
            console.log(`Filtered potentially unbounded resizing loop. TimeSinceLastUpdate: ${timeSinceLastUpdate}. ConsecutiveUpdateCount: ${state.consecutiveUpdateCount}.`)
          }
        }
      }

      el.addEventListener('resize', doScale)
      window.addEventListener("resize", doScale)
      doScale()

      return () => {
        el.removeEventListener("resize", doScale)
        window.removeEventListener("resize", doScale)
      }
    }
  }, [ref.current, state])

  return (
    <>
      {children(ref, state.scale)}
    </>
  )
}
// extracted by mini-css-extract-plugin
export var inactiveLink = "HomepageHeaderSection-module--inactiveLink--mnDJf";
export var sectionContainer = "HomepageHeaderSection-module--sectionContainer--yM80X";
export var promotionContainer = "HomepageHeaderSection-module--promotionContainer--vV_C8";
export var sidebar = "HomepageHeaderSection-module--sidebar--12YJP";
export var content = "HomepageHeaderSection-module--content--2NPVM";
export var homepageHeaderSectionContainer = "HomepageHeaderSection-module--homepageHeaderSectionContainer--3eQEs";
export var subTitle = "HomepageHeaderSection-module--subTitle--2YywU";
export var speechBubble = "HomepageHeaderSection-module--speechBubble--1nM_m";
export var bubbleContent = "HomepageHeaderSection-module--bubbleContent--30Fyw";
export var bubbleLine1 = "HomepageHeaderSection-module--bubbleLine1--2fcez";
export var bubbleLine2 = "HomepageHeaderSection-module--bubbleLine2--2nDmZ";
export var bubbleLine3 = "HomepageHeaderSection-module--bubbleLine3--3ewjj";
export var bubbleLine4 = "HomepageHeaderSection-module--bubbleLine4--3Q5lx";
import React, { useEffect, useState } from "react"
import Section from "./Section"
import { graphql, useStaticQuery } from "gatsby"
import * as styles from "./LogoCarouselSection.module.scss"
import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"
import Slider from "react-slick"
import { IStrapiImageStaticImage } from "../../model/IStrapiImageStaticImage"

interface LogoCarouselSectionProps {
  supplierLogos: {
    logos: (IStrapiImageStaticImage & {
      id: string
    })[],
    title: string
  }
}

export const LogoCarouselSection: React.FC<LogoCarouselSectionProps> = () => {
  const [reducingMotion, setReducingMotion] = useState<boolean>(false)
  const data = useStaticQuery<{ strapi: {globalConfig: LogoCarouselSectionProps} }>(query)
  const config = data.strapi.globalConfig.supplierLogos

  useEffect(() => {
    try {
      setReducingMotion(window.matchMedia('(prefers-reduced-motion: reduce)').matches)
    } catch (ex) {
      setReducingMotion(false)
    }
  }, [])

  const SLIDER_SETTINGS = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
    slidesToScroll: 1,
    autoplay: !reducingMotion,
    speed: (reducingMotion) ? undefined : 4000,
    autoplaySpeed: (reducingMotion) ? undefined : 4000,
    cssEase: "linear",
    centerMode: true,
    draggable: reducingMotion,
    swipe: reducingMotion,
    touchMove: reducingMotion,
    className: styles.logosContainer
  }

  const logosElements = config.logos.map((logo, index) => (
    <div key={`LogoCarouselSection-Logo-${logo.id}-${index}`} className={styles.logo}>
      <img
        alt={logo.alternativeText}
        title={logo.alternativeText}
        src={logo.urlSharp.staticURL}
      />
    </div>
  ))

  return (
    <Section className={styles.LogoCarouselSection}>
      <h2 className={styles.title}>{config.title}</h2>
      <div className={styles.logosContainerContainer}>
        <Slider {...SLIDER_SETTINGS}>
          {logosElements}
        </Slider>
      </div>
    </Section>
  )
}

const query = graphql`
  query {
    strapi {
      globalConfig {
        supplierLogos {
          title
          logos {
            id
            url
            alternativeText
            urlSharp {
              staticURL
            }
          }
        }
      }
    }
  }
`
// extracted by mini-css-extract-plugin
export var showSchoolMenuSectionContainer = "ShowSchoolMenuSection-module--showSchoolMenuSectionContainer--1YfEv";
export var sidebar = "ShowSchoolMenuSection-module--sidebar--1YqK_";
export var sidebarHighlight = "ShowSchoolMenuSection-module--sidebarHighlight--2sxX8";
export var sidebarMain = "ShowSchoolMenuSection-module--sidebarMain--3oqlK";
export var content = "ShowSchoolMenuSection-module--content--3Xyez";
export var schoolMenuHeader = "ShowSchoolMenuSection-module--schoolMenuHeader--3rPLw";
export var schoolMenuDescription1 = "ShowSchoolMenuSection-module--schoolMenuDescription1--1oM4H";
export var schoolMenuDescription2 = "ShowSchoolMenuSection-module--schoolMenuDescription2--2SVeb";
export var schoolMenuDescription3 = "ShowSchoolMenuSection-module--schoolMenuDescription3--3Umfn";
export var schoolMenuButton = "ShowSchoolMenuSection-module--schoolMenuButton--AtbLR";
import React from "react"
import Section from "./Section"
import { GatsbyImage } from "gatsby-plugin-image"
import { IStrapiImage } from "../../model/IStrapiImage"
import { graphql } from "gatsby"
import { SpeechBubble } from "../SpeechBubble"
import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"
import Slider from "react-slick"
import * as styles from "./HomepageHeaderSection.module.scss"
import { RichText } from "../RichText"

export interface HomepageHeaderProps {
  bubbleLine1: string,
  bubbleLine2: string,
  bubbleLine3: string,
  bubbleLine4: string,
  subTitle: string,
  homeBannerImg: IStrapiImage,
  promotionImage1: IStrapiImage,
  promotionImage2: IStrapiImage,
  promotionImage3: IStrapiImage,
  promoUrl1: string,
  promoUrl2: string,
  promoUrl3: string
}
export const HomepageHeaderSection: React.FC<HomepageHeaderProps> = ({bubbleLine1, bubbleLine2, bubbleLine3, bubbleLine4, subTitle, homeBannerImg, promotionImage1, promotionImage2, promotionImage3, promoUrl1, promoUrl2, promoUrl3}) => {
  const sliderSettings =  {
    arrows: false,
    dots: false,
    autoplay: true,
    lazyLoad: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: -1
  };

  return (
    <Section className={styles.sectionContainer}>
      <div className={styles.homepageHeaderSectionContainer}>
        <div className={styles.sidebar}>
          <SpeechBubble className={styles.speechBubble}>
            <h1 className={styles.bubbleContent}>
              <span className={styles.bubbleLine1}>{bubbleLine1}</span><br/>
              <span className={styles.bubbleLine2}>{bubbleLine2}</span><br/>
              <span className={styles.bubbleLine3}>{bubbleLine3}</span><br/>
              <span className={styles.bubbleLine4}>{bubbleLine4}</span>
            </h1>
          </SpeechBubble>
        </div>
        <div className={styles.content}>
        <Slider { ...sliderSettings }>
          <GatsbyImage loading="eager" image={homeBannerImg.urlSharp.childImageSharp.gatsbyImageData} alt={homeBannerImg.alternativeText}/>
          { promotionImage1 ? 
          <a href={promoUrl1 ? promoUrl1 : ''} className={!promoUrl1 ? styles.inactiveLink : ""} target={"_blank"} rel="noreferrer">
            <GatsbyImage loading="eager" image={promotionImage1.urlSharp.childImageSharp.gatsbyImageData} alt={promotionImage1.alternativeText} />
          </a>
          : undefined }
          { promotionImage2 ? 
          <a href={promoUrl2 ? promoUrl2 : ''} className={!promoUrl2 ? styles.inactiveLink : ""} target={"_blank"} rel="noreferrer">
            <GatsbyImage loading="eager" image={promotionImage2.urlSharp.childImageSharp.gatsbyImageData} alt={promotionImage2.alternativeText}/>
          </a>
          : undefined }
          { promotionImage3 ? 
          <a href={promoUrl3 ? promoUrl3 : ''} className={!promoUrl3 ? styles.inactiveLink : ""} target={"_blank"} rel="noreferrer">
            <GatsbyImage loading="eager" image={promotionImage3.urlSharp.childImageSharp.gatsbyImageData} alt={promotionImage3.alternativeText}/>
          </a>
          : undefined }
        </Slider>
        </div>
      </div>
      <RichText className={styles.subTitle} text={subTitle} />
    </Section>
  )
}

export default HomepageHeaderSection;

export const homepageHeaderSectionFragment = graphql`
  fragment homepageHeaderSectionFragment on Strapi_ComponentSectionHomeHeader {
    bubbleLine1
    bubbleLine2
    bubbleLine3
    bubbleLine4
    subTitle
    homeBannerImg {
      id
      url
      alternativeText
      urlSharp {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100)
        }
      }
    }
    promotionImage1 {
      id
      url
      alternativeText
      urlSharp {
        childImageSharp {
          gatsbyImageData(width: 2000, quality: 100)
        }
      }
    }
    promotionImage2 {
      id
      url
      alternativeText
      urlSharp {
        childImageSharp {
          gatsbyImageData(width: 2000, quality: 100)
        }
      }
    }
    promotionImage3 {
      id
      url
      alternativeText
      urlSharp {
        childImageSharp {
          gatsbyImageData(width: 2000, quality: 100)
        }
      }
    }
    promoUrl1
    promoUrl2
    promoUrl3
  }
`
import React from "react"
import { graphql } from "gatsby"
import { IStrapiImage } from "../../model/IStrapiImage"
import { IStrapiImageStaticImage } from "../../model/IStrapiImageStaticImage"
import * as styles from "./HowItWorksSection.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import Section from "./Section"
import { SpeechBubble } from "../SpeechBubble"
import { RichText } from "../RichText"
import { mergeClassNames } from "../../util/util"
import TickIcon from "../../images/tick.svg"

interface BubbleSteps {
  id: string,
  stepBold: string,
  stepNumber: string,
  stepNumberColour: string,
  stepText: string
}

interface HowItWorksSectionProps {
  howItWorksHeader: string,
  howItWorksHeaderColor: string,
  bubbleSteps: BubbleSteps[],
  middleImage: IStrapiImage,
  rowCard: {
    id: string,
    firstDescription: string,
    secondDescription: string,
    thirdDescription: string,
    headerImg: IStrapiImage,
    firstIcon: IStrapiImageStaticImage,
    secondIcon: IStrapiImageStaticImage,
    thirdIcon: IStrapiImageStaticImage
  },
  localMenuCard: {
    menuDescription: string,
    menuHeader: string,
    menuImg1: IStrapiImage,
    menuImg2: IStrapiImage,
    menuImg3: IStrapiImage,
    menuImg4: IStrapiImage
  }
}

export const HowItWorksSection: React.FC<HowItWorksSectionProps> = ({howItWorksHeader, howItWorksHeaderColor, bubbleSteps, middleImage, rowCard, localMenuCard}) => {
  return (
    <Section>
      <h2 id="howItWorks" className={styles.howItWorksHeader} style={{ color: howItWorksHeaderColor}}>{howItWorksHeader}</h2>
      <div className={styles.howItWorksSectionContainer}>
        <div className={styles.left}>
          <SpeechBubble
            className={styles.speechBubble}
            style={{
              top: 0,
              left: 0,
              overflow: "shown"
            }}>
            <span className={styles.bubbleContent}>
              {bubbleSteps.map((bubbleStep) => (
                <div key={`bubbleStep-${bubbleStep.id}`}>
                  <span style={{ color: bubbleStep.stepNumberColour}} className={styles.stepNumber}>{bubbleStep.stepNumber}</span>
                  <span className={styles.stepBold}>{bubbleStep.stepBold}</span>
                  <span className={styles.stepText}>{bubbleStep.stepText}</span>
                </div>
              ))}
            </span>
          </SpeechBubble>
        </div>
        <div className={styles.content}>
          <TickIcon style={{ width: 60, position: "absolute" }} />
          <GatsbyImage className={styles.contentImage} image={middleImage.urlSharp.childImageSharp.gatsbyImageData} alt={middleImage.alternativeText}/>
        </div>
        {(rowCard === null) ? undefined : (
          <div className={styles.right}>
            <div className={styles.headerImg}>
              <GatsbyImage alt={rowCard.headerImg.alternativeText} image={rowCard.headerImg.urlSharp.childImageSharp.gatsbyImageData} />
            </div>
            <div className={styles.icon}>
              <img alt={rowCard.firstIcon.alternativeText} src={rowCard.firstIcon.urlSharp.staticURL}/>
            </div>
            <RichText className={styles.description} text={rowCard.firstDescription} />
            <div className={styles.icon}>
              <img alt={rowCard.secondIcon.alternativeText} src={rowCard.secondIcon.urlSharp.staticURL}/>
            </div>
            <RichText className={styles.description} text={rowCard.secondDescription} />
            {(rowCard.thirdIcon === null) ? undefined : (
              <>
                <div className={styles.icon}>
                  <img alt={rowCard.thirdIcon.alternativeText} src={rowCard.thirdIcon.urlSharp.staticURL}/>
                </div>
                <RichText className={styles.description} text={rowCard.thirdDescription} />
              </>
            )}
          </div>
        )}
        {(localMenuCard === null) ? undefined : (
          <div className={mergeClassNames(styles.right, styles.localMenuBackground)}>
            <div className={styles.localMenuHeader}>
              {localMenuCard.menuHeader}
            </div>
            <div className={styles.localMenuImageContainer}>
              <GatsbyImage alt={localMenuCard.menuImg1.alternativeText} image={localMenuCard.menuImg1.urlSharp.childImageSharp.gatsbyImageData} />
              <GatsbyImage alt={localMenuCard.menuImg2.alternativeText} image={localMenuCard.menuImg2.urlSharp.childImageSharp.gatsbyImageData} />
              <GatsbyImage alt={localMenuCard.menuImg3.alternativeText} image={localMenuCard.menuImg3.urlSharp.childImageSharp.gatsbyImageData} />
              <GatsbyImage alt={localMenuCard.menuImg4.alternativeText} image={localMenuCard.menuImg4.urlSharp.childImageSharp.gatsbyImageData} />
            </div>
            <div className={styles.localMenuDescription}>
              <RichText  text={localMenuCard.menuDescription} />
            </div>   
          </div>
        )}
      </div>
    </Section>
  )
}

export const howItWorksSectionFragment = graphql`
  fragment howItWorksSectionFragment on Strapi_ComponentSectionHowItWorks {
    howItWorksHeader
    howItWorksHeaderColor
    rowCard {
      id
      firstDescription
      secondDescription
      thirdDescription
      firstIcon {
        url
        alternativeText
        urlSharp {
          staticURL
        }
      }
      headerImg {
        url
        urlSharp {
          childImageSharp {
            gatsbyImageData(width: 184, quality: 100)
          }
        }
      }
      secondIcon {
        url
        alternativeText
        urlSharp {
          staticURL
        }
      }
      thirdIcon {
        url
        alternativeText
        urlSharp {
          staticURL
        }
      }
    }
    bubbleSteps {
      id
      stepBold
      stepNumber
      stepNumberColour
      stepText
    }
    middleImage {
      url
      alternativeText
      urlSharp {
        childImageSharp {
          gatsbyImageData(width: 260, quality: 100)
        }
      }
    }
    localMenuCard {
      menuDescription
      menuHeader
      menuImg1 {
        url
        alternativeText
        urlSharp {
          childImageSharp {
            gatsbyImageData(width: 80, quality: 100)
          }
        }
      }
      menuImg2 {
        url
        alternativeText
        urlSharp {
          childImageSharp {
            gatsbyImageData(width: 80, quality: 100)
          }
        }
      }
      menuImg3 {
        url
        alternativeText
        urlSharp {
          childImageSharp {
            gatsbyImageData(width: 80, quality: 100)
          }
        }
      }
      menuImg4 {
        url
        alternativeText
        urlSharp {
          childImageSharp {
            gatsbyImageData(width: 80, quality: 100)
          }
        }
      }
    }
  }
`
import React from "react"
import { TestimonialsSection } from "./TestimonialsSection"
import { LogoCarouselSection } from "./LogoCarouselSection"
import { FamiliesSchoolsCafesStatisticsOverviewSection } from "./FamiliesSchoolsCafesStatisticsOverviewSection"
import { TwoColumnPitchSection } from "./two_column_pitch_section/TwoColumnPitchSection"
import { PicturesAndBody } from "./PicturesAndBody"
import { QuestionsAndAnswersSection } from "./QuestionsAndAnswersSection"
import { FourColumnSection } from "./FourColumnSection"
import { TwoColumnTalkSection } from "./TwoColumnTalkSection"
import { ContactUsSection } from "./ContactUsSection"
import { ShowSchoolMenuSection } from "./show_school_menu_section/ShowSchoolMenuSection"
import { HowItWorksSection } from "./HowItWorksSection"
import { OurStorySection } from "./OurStorySection"
import { HomepageHeaderSection } from "./HomepageHeaderSection"
import { RightPictureAndBodySection } from "./RightPictureAndBodySection"
import { ThreeColumnCaseShareSection } from "./ThreeColumnCaseShareSection"
import { NewsSection } from "./NewsSection"
import { FamiliesSchoolsCafesHeaderSection } from "./FamiliesSchoolsCafesHeaderSection"

interface RequiredSectionProps {
  id: string,
  typeName: string
}
export type SectionProps = RequiredSectionProps & any

interface SectionMapperProps {
  section: SectionProps
}
export const SectionMapper: React.FC<SectionMapperProps> = ({section}) => {
  switch (section.typeName) {
    case "Strapi_ComponentSectionTestimonials":
      return <TestimonialsSection {...section}/>
    case "Strapi_ComponentSectionLogoSlideshow":
      return <LogoCarouselSection {...section}/>
    case "Strapi_ComponentSectionFamiliesSchoolsCafesStatisticsOverview":
      return <FamiliesSchoolsCafesStatisticsOverviewSection {...section}/>
    case "Strapi_ComponentSectionTwoColumnPitch":
      return <TwoColumnPitchSection {...section}/>
    case "Strapi_ComponentSectionTwoPicturesAndBody":
      return <PicturesAndBody {...section} />
    case "Strapi_ComponentSectionFourColumnCard":
      return <FourColumnSection {...section}/>
    case "Strapi_ComponentSectionQuestionsAndAnswers":
      return <QuestionsAndAnswersSection {...section} />
    case "Strapi_ComponentSectionTwoColumnTalk":
      return <TwoColumnTalkSection {...section} />
    case "Strapi_ComponentSectionContactUs":
        return <ContactUsSection {...section} />
    case "Strapi_ComponentSectionShowSchoolMenu":
      return <ShowSchoolMenuSection {...section} />
    case "Strapi_ComponentSectionHowItWorks":
      return <HowItWorksSection {...section} />
    case "Strapi_ComponentSectionOurStory":
      return <OurStorySection {...section} />
    case "Strapi_ComponentSectionHomeHeader":
      return <HomepageHeaderSection {...section} />
    case "Strapi_ComponentSectionRightPictureAndBody":
      return <RightPictureAndBodySection {...section} />
    case "Strapi_ComponentSectionThreeColumnCaseShare":
      return <ThreeColumnCaseShareSection {...section} />
    case "Strapi_ComponentSectionNews":
      return <NewsSection {...section} />
    case "Strapi_ComponentSectionFamiliesSchoolsCafesHeader":
      return <FamiliesSchoolsCafesHeaderSection {...section} />
    default:
      console.log(section)
      return (
        <div>ERROR: Failed to load section with typeName: {section.typeName}</div>
      )
  }
}
import {graphql} from "gatsby"
import React, {useRef, useState} from "react"
import * as styles from "./QuestionsAndAnswersSection.module.scss"
import {RichText} from "../RichText"

interface QuestionsAndAnswersType {
  id: string,
  question: string,
  answer: string
}

interface QuestionsAndAnswersSectionProps {
  heading: string,
  colour: string,
  questionsandanswers: QuestionsAndAnswersType[],
}

export const QuestionsAndAnswersSection: React.FC<QuestionsAndAnswersSectionProps> = ({heading, colour, questionsandanswers}) => {
  return (
    <div className={styles.QuestionsAndAnswersSection}>
      <h2 style={{color: `${colour}`}} className={styles.heading}>{heading}</h2>
      <div>
        {questionsandanswers.map((question) => (
          <QuestionAndAnswer
            key={`Questions-${question.id}`}
            {...question}
          />
        ))}
      </div>
    </div>
  )
}

const QuestionAndAnswer: React.FC<QuestionsAndAnswersType> = ({question, answer}) => {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");
  const content = useRef<HTMLDivElement | null>(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content?.current?.scrollHeight ?? 0}px`
    );
    setRotateState(
      setActive === "active" ? "rotate(-90deg)" : "rotate(0deg)"
    );
  }


  return (
    <div>
      <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <p className={styles.accordionTitleContainer}>
          <div className={styles.accordionButtonContainer}>
            <span style={{transform: `${setRotate}`}} className={styles.accordionButton}/>
          </div>
          <div className={styles.accordionTitle}>{question}</div>
        </p>
      </button>
      <div ref={content} style={{maxHeight: `${setHeight}`}} className={styles.accordionContent}>
        <RichText className={styles.accordionText} text={answer}/>
      </div>
      <div>
        <hr className={styles.accordionLine}/>
      </div>
    </div>
  )
}

export const questionsAndAnswersSectionFragment = graphql`
  fragment questionsAndAnswersSectionFragment on Strapi_ComponentSectionQuestionsAndAnswers {
    id
    heading
    colour
    questionsandanswers {
      answer
      id
      question
    }
  }
`
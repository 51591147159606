import React, {useEffect} from "react";

interface DANGEROUS_HtmlAndScriptProps {
  html: string
  script: string
}
export const DANGEROUS_HtmlAndScript: React.FC<DANGEROUS_HtmlAndScriptProps> = ({html, script}) => {
  useEffect(() => {
    // Evaluate the script after the content is rendered.
    window.eval(script)
  }, [])

  return (
    <div dangerouslySetInnerHTML={{__html: html}}/>
  )
}
import React from "react"
import { graphql } from "gatsby"
import { IPageButton } from "../PageButton"
import Section from "./Section"
import * as styles from "./RightPictureAndBodySection.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { IStrapiImage } from "../../model/IStrapiImage"
import { SectionHeadingBodyButtonsColumn } from "../SectionHeadingBodyButtonsColumn"

interface RightPictureAndBodySectionProps {
  heading: string,
  _body: string,
  actionButtons: IPageButton[],
  picture: IStrapiImage
}
export const RightPictureAndBodySection: React.FC<RightPictureAndBodySectionProps> = ({heading, _body: body, actionButtons, picture}) => {
  return (
    <Section className={styles.RightPictureAndBodySection}>
      <SectionHeadingBodyButtonsColumn className={styles.content} heading={heading} body={body} actionButtons={actionButtons}/>

      <div className={styles.picture}>
        <GatsbyImage alt={picture.alternativeText} image={picture.urlSharp.childImageSharp.gatsbyImageData}/>
      </div>
    </Section>
  )
}

export const rightPictureAndBodySectionFragment = graphql`
  fragment rightPictureAndBodySectionFragment on Strapi_ComponentSectionRightPictureAndBody {
    heading
    _body: body
    picture {
      url
      alternativeText
      urlSharp {
        childImageSharp {
          gatsbyImageData(width: 765, quality: 90)
        }
      }
    }
    actionButtons {
      ...pageButtonFragment
    }
  }
`
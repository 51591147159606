import { SCHOOL_ALIAS_LIST } from "../../../config"

const buildEquivalentSchoolNameGeneratorFunction = (): ((schoolId: string) => string[]) => {
  /*Returns a function that converts school_ids into an array of 'equivalent' ids based on aliases */
  const mkReplaceFn = function(strFrom: string, strTo: string) {
    /* return a one-way function for an alias pair*/
    const reStarts = new RegExp("^" + strFrom + " ")
    const reContains = new RegExp(" " + strFrom + " ", "g")
    const reEnds = new RegExp(" " + strFrom + "$")
    const fnOneWay = function(tok: string) {
      return tok.replace(reStarts, strTo + " ").replace(reContains, " " + strTo + " ").replace(reEnds, " " + strTo)
    }
    return fnOneWay

  }
  const mkEquivFnForPair = function(pair: [string, string]) { /* given a pair like ['st','saint'] return a function that will do a global replace in both directions*/
    /* return a two way function for an alias pair
     * the function returned first tries forward, and (only if result of forward is unchanged) tries backward
     */
    const fn1 = mkReplaceFn(pair[0].trim(), pair[1].trim()) //forward function
    const fn2 = mkReplaceFn(pair[1].trim(), pair[0].trim()) //backwards function
    const fnTwoWay = function(s0: string) {
      const s1 = fn1(s0)
      if (s1 !== s0) {
        return s1 //string was altered, so return now
      } else {
        return fn2(s1) //may still return same string as s0
      }
    }
    return fnTwoWay
  }

  const fnsToApply = SCHOOL_ALIAS_LIST.map(mkEquivFnForPair)

  const finalFunction = function(school_id: string) {
    const equivs = []
    for (let i = 0; i < fnsToApply.length; i++) {
      const equivOrSame = fnsToApply[i](school_id)
      if (school_id !== equivOrSame) equivs.push(equivOrSame)
    }
    return equivs
  }

  return finalFunction
}
export const equivalentSchoolNameGeneratorFunction = buildEquivalentSchoolNameGeneratorFunction()
import React, { ChangeEvent, useCallback, useEffect, useMemo, useReducer, useState } from "react"
import * as styles from "./SchoolSearchBox.module.scss"
import loadFetchPolyfill from "../../../util/compat/loadFetchPolyfill"
import { mergeClassNames, pathify } from "../../../util/util"
import { RaisedButton } from "../../button/RaisedButton"
import { FlatButton } from "../../button/FlatButton"
import leven from "leven"
import { equivalentSchoolNameGeneratorFunction } from "./util"

interface SchoolSearchBoxSchool {
  school_id: string,
  roll_status: {
    w_join: boolean,
    s_join: boolean
  }
}

/*
* SchoolSearchBox State
*/
enum SchoolSearchBoxLoadingState {
  Unloaded,
  Pending,
  Loaded,
  Error
}
enum SchoolSearchBoxStateActionType {
  TriggerLoad,
  LoadSuccessful,
  LoadFailure
}
interface SchoolSearchBoxState {
  loadSchoolListStatus: SchoolSearchBoxLoadingState,
  schools?: SchoolSearchBoxSchool[]
}
interface SchoolSearchBoxStateAction {
  type: SchoolSearchBoxStateActionType
}
interface SchoolSearchBoxStateActionLoaded extends SchoolSearchBoxStateAction {
  type: SchoolSearchBoxStateActionType.LoadSuccessful,
  schools: SchoolSearchBoxSchool[]
}
const SchoolSearchBoxReducer: React.Reducer<SchoolSearchBoxState, SchoolSearchBoxStateAction> = (state, action) => {
  switch (action.type) {
    case SchoolSearchBoxStateActionType.TriggerLoad:
      return {
        loadSchoolListStatus: SchoolSearchBoxLoadingState.Pending
      }
    case SchoolSearchBoxStateActionType.LoadFailure:
      return {
        loadSchoolListStatus: SchoolSearchBoxLoadingState.Error
      }
    case SchoolSearchBoxStateActionType.LoadSuccessful:
      return {
        loadSchoolListStatus: SchoolSearchBoxLoadingState.Loaded,
        schools: (action as SchoolSearchBoxStateActionLoaded).schools
      }
    default:
      throw new Error("Unsupported action triggered.")
  }
}

/*
* SchoolSearchBox
*/
interface SchoolSearchBoxProps {
  className?: string,
  highlightColour: string
}
export const SchoolSearchBox: React.FC<SchoolSearchBoxProps> = (props) => {
  const [state, dispatch] = useReducer(SchoolSearchBoxReducer, {
    loadSchoolListStatus: SchoolSearchBoxLoadingState.Unloaded
  })

  // Called when we want to reload the school data.
  // Warning: Should be called *after* polyfills have been loaded (if necessary)
  const startLoadingCallback = useCallback(async () => {
    dispatch({
      type: SchoolSearchBoxStateActionType.TriggerLoad
    })

    // In future this may be a CORS request to shop.tgcl.co.nz or similar.
    // For now, assumes that the /school_list.json file is kept up to date.
    const url = `/school_list.json`
    try {
      const res = await fetch(url, {
        credentials: 'omit'
      })

      if (!res.ok) {
        return dispatch({
          type: SchoolSearchBoxStateActionType.LoadFailure
        })
      }

      const toDispatch: SchoolSearchBoxStateActionLoaded = {
        type: SchoolSearchBoxStateActionType.LoadSuccessful,
        schools: await res.json()
      }
      return dispatch(toDispatch)
    }
    catch (ex) {
      console.error(ex)
      return dispatch({
        type: SchoolSearchBoxStateActionType.LoadFailure
      })
    }
  }, [])
  useEffect(() => {
    (async () => {
      await loadFetchPolyfill()
      await startLoadingCallback()
    })()
  }, [startLoadingCallback])

  const errorContent = (
    <div>
      Failed to load schools. <FlatButton onClick={startLoadingCallback}>Retry</FlatButton>
    </div>
  )

  switch (state.loadSchoolListStatus) {
    case SchoolSearchBoxLoadingState.Loaded:
      if (state.schools !== undefined) {
        return <SchoolSearchBoxContent {...props} schools={state.schools}/>
      }
      return errorContent
    case SchoolSearchBoxLoadingState.Error:
      return errorContent
    default:
      return (
        <div>
          Loading school search...
        </div>
      )
  }
}

/*
* SchoolSearchBoxContent State
*/
interface SchoolSearchBoxContentState {
  currentText: string,
  currentMatches: {
    distance: number,
    aliasIndex: number
  }[]
}
interface SchoolSearchBoxSearchableSchool {
  school_index: number,
  searchable_name: string
}

/*
* SchoolSearchBoxContent
*/
interface SchoolSearchBoxContentProps extends SchoolSearchBoxProps {
  schools: SchoolSearchBoxSchool[]
}
const SchoolSearchBoxContent: React.FC<SchoolSearchBoxContentProps> = ({className, highlightColour, schools}) => {
  const COMPLETE_MATCH_MAX_DISTANCE = 0.1
  const [state, setState] = useState<SchoolSearchBoxContentState>({
    currentText: "",
    currentMatches: []
  })

  // Contains a list of all aliases of all schools
  const searchableSchools: SchoolSearchBoxSearchableSchool[] = useMemo(() => {
    const results: SchoolSearchBoxSearchableSchool[] = []

    for (let i=0; i < schools.length; i++) {
      const school = schools[i]

      results.push({
        school_index: i,
        searchable_name: school.school_id.toLowerCase()
      })

      for (const alias of equivalentSchoolNameGeneratorFunction(school.school_id.toLowerCase())) {
        results.push({
          school_index: i,
          searchable_name: alias
        })
      }
    }

    return results
  }, [schools])

  // Updates state when the search string changes
  const onSearchChange = useCallback((newSearch: string) => {
    if (/^\s*$/.test(newSearch)) {
      // Is empty or entirely whitespace
      setState({
        currentText: '',
        currentMatches: []
      })
    }
    else {
      const distances = []

      for (let i=0; i < searchableSchools.length; i++) {
        const schoolAlias = searchableSchools[i].searchable_name;
        distances.push({
          distance: leven(schoolAlias, newSearch.toLowerCase()) / schoolAlias.length,
          aliasIndex: i
        })
      }

      // Sort the distances in ascending order
      distances.sort((firstEl, secondEl) => {
        return firstEl.distance - secondEl.distance
      })

      setState({
        currentText: newSearch,
        currentMatches: distances
      })
    }
  }, [searchableSchools])
  const onSearchInputChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    return onSearchChange(evt.target.value)
  }, [onSearchChange])

  // Computes the current matches based on the current state
  const matches = useMemo(() => {
    const matches: string[] = []
    for (let i=0; i < state.currentMatches.length; i++) {
      const currentMatch = state.currentMatches[i]
      const currentMatchSchool = schools[searchableSchools[currentMatch.aliasIndex].school_index].school_id

      if (currentMatch.distance > 1) {
        // this is far from a match. No need to look further.
        break
      }

      if (matches.indexOf(currentMatchSchool) === -1) {
        matches.push(currentMatchSchool)
      }

      if (currentMatch.distance <= COMPLETE_MATCH_MAX_DISTANCE) {
        // This is practically a complete match. No need to look further.
        break
      }
    }
    return matches
  }, [state.currentMatches, searchableSchools, schools])
  const hasCompleteMatch = useMemo(() => {
    return state.currentMatches.length >= 1 && state.currentMatches[0].distance <= COMPLETE_MATCH_MAX_DISTANCE
  }, [state.currentMatches])

  // Called when the user clicks to view the menu for the current school
  const showSchoolMenu = useCallback(() => {
    if (hasCompleteMatch) {
      const schoolId = matches[0]

      window.open(`https://shop.tgcl.co.nz/shop/pdf/school/${pathify(schoolId)}/Menu.pdf`, '_blank')
    }
  }, [matches, schools])

  // Triggers a re-search if the schools props ever changes
  useEffect(() => {
    onSearchChange(state.currentText)
  }, [schools])

  if (schools.length === 0) {
    return <></>
  }

  return (
    <div className={mergeClassNames(styles.SchoolSearchBox, className)}>
      <div className={styles.searchContainer}>
        <input
          className={styles.searchInput}
          onChange={onSearchInputChange}
          value={state.currentText}
          placeholder='type the name of your school here'
        />

        {(hasCompleteMatch || matches.length === 0) ? undefined : (
          <div className={styles.searchSuggestions}>
            {(matches.map((school_id) => (
              <div
                key={`SchoolSearchBox-Match-${school_id}`}
                className={styles.suggestion}
                onClick={() => onSearchChange(school_id)}
              >
                {school_id}
              </div>
            )))}
          </div>
        )}
      </div>

      <RaisedButton
        className={mergeClassNames(styles.searchButton, (hasCompleteMatch) ? undefined : styles.disabled)}
        onClick={showSchoolMenu}
        style={{
          backgroundColor: (hasCompleteMatch) ? highlightColour : undefined,
        }}
      >Show school menu</RaisedButton>
    </div>
  )
}
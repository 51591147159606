import React from "react"

interface StringParagraphProps {
  para: string
}
export const StringParagraph: React.FC<StringParagraphProps> = ({para}) => {
  const lines = para.split(/\n/)

  return (
    <>
      {lines.map((line, index) => (
        <React.Fragment key={`StringParagraph-Line-${index}`}>
          {line}
          {(index !== lines.length - 1) ? <br/> : undefined}
        </React.Fragment>
      ))}
    </>
  )
}
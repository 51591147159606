import React from "react"
import * as styles from "./ContactUsSection.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import { IPhoneNumber } from "../../model/IPhoneNumber"
import { StaticImage } from "gatsby-plugin-image"
import Section from "./Section"
import { IStrapiImage } from "../../model/IStrapiImage"
import Contact from "./ContactForm.html"
import { DANGEROUS_HtmlAndScript } from "../DANGEROUS_HtmlAndScript";

const CONTACT_FORM_SCRIPT = `
  function rccallback428265000039389103() {
    if(document.getElementById('recap428265000039389103')!=undefined){
      document.getElementById('recap428265000039389103').setAttribute('captcha-verified',true);
    }
    if(document.getElementById('recapErr428265000039389103')!=undefined && document.getElementById('recapErr428265000039389103').style.visibility == 'visible' ){
      document.getElementById('recapErr428265000039389103').style.visibility='hidden';
    }
  }
  function reCaptchaAlert428265000039389103() {
    var recap = document.getElementById('recap428265000039389103');
    if( recap !=undefined && recap.getAttribute('captcha-verified') == 'false')
    {
      document.getElementById('recapErr428265000039389103').style.visibility='visible';
      return false;
    }
    return true;
  }
  function validateEmail428265000039389103() {
    var form = document.forms['WebToLeads428265000039389103'];
    var emailFld = form.querySelectorAll('[ftype=email]');
    var i;
    for (i = 0; i < emailFld.length; i++) {
      var emailVal = emailFld[i].value;
      if((emailVal.replace(/^\\s+|\\s+$/g, '')).length!=0 ) {
        var atpos=emailVal.indexOf('@');
        var dotpos=emailVal.lastIndexOf('.');
        if (atpos<1 || dotpos<atpos+2 || dotpos+2>=emailVal.length) {
          alert('Please enter a valid email address. ');
          emailFld[i].focus();
          return false;
        }
      }
    }
    return true;
  }
  function checkMandatory428265000039389103() {
    var mndFileds = new Array('Company','First Name','Last Name','Email','Phone','Description','LEADCF8','LEADCF9');
		var fldLangVal = new Array('Organisation','First\x20Name','Last\x20Name','Email','Phone','Description','Location','Enquiry\x20type');
		for(i=0;i<mndFileds.length;i++) {
		  var fieldObj=document.forms['WebToLeads428265000039389103'][mndFileds[i]];
		  if(fieldObj) {
        if (((fieldObj.value).replace(/^\\s+|\\s+$/g, '')).length==0) {
          if(fieldObj.type =='file')
				{ 
				 alert('Please select a file to upload.'); 
				 fieldObj.focus(); 
				 return false;
				} 
			alert(fldLangVal[i] +' cannot be empty.'); 
   	   	  	  fieldObj.focus();
   	   	  	  return false;
			}  else if(fieldObj.nodeName=='SELECT') {
  	   	   	 if(fieldObj.options[fieldObj.selectedIndex].value=='-None-') {
				alert(fldLangVal[i] +' cannot be none.'); 
				fieldObj.focus();
				return false;
			   }
			} else if(fieldObj.type =='checkbox'){
 	 	 	 if(fieldObj.checked == false){
				alert('Please accept  '+fldLangVal[i]);
				fieldObj.focus();
				return false;
			   } 
			 } 
			 try {
			     if(fieldObj.name == 'Last Name') {
				name = fieldObj.value;
 	 	 	    }
			} catch (e) {}
		    }
		}
		if(!validateFileUpload428265000039389103()){return false;}

		if(!validateEmail428265000039389103()){return false;}
		
		if(!reCaptchaAlert428265000039389103()){return false;}
		document.querySelector('.crmWebToEntityForm .formsubmit').setAttribute('disabled', true);
  }
  function validateFileUpload428265000039389103() {
    var uploadedFiles = document.getElementById('theFile428265000039389103'); 
    var totalFileSize =0; 
    if(uploadedFiles.files.length >3){ 
      alert('You can upload a maximum of three files at a time.'); 
      return false; 
    } 
    if ('files' in uploadedFiles) { 
      if (uploadedFiles.files.length != 0) { 
        for (var i = 0; i < uploadedFiles.files.length; i++) { 
          var file = uploadedFiles.files[i]; 
          if ('size' in file) { 
            totalFileSize = totalFileSize + file.size; 
          } 
        } 
        if(totalFileSize > 20971520){ 
          alert('Total file(s) size should not exceed 20MB.'); 
          return false; 
        } 
      } 
    } 
    return true; 
  }

  function tooltipShow428265000039389103(el) {
    var tooltip = el.nextElementSibling;
    var tooltipDisplay = tooltip.style.display;
    if(tooltipDisplay == 'none'){
      var allTooltip = document.getElementsByClassName('zcwf_tooltip_over');
      for(i=0; i<allTooltip.length; i++){
        allTooltip[i].style.display='none';
      }
      tooltip.style.display = 'block';
    }else{
      tooltip.style.display='none';
    }
  }

`

interface ContactUsSectionProps {
  strapi: {
    globalConfig: {
      localNumber: IPhoneNumber,
      nationalNumber: IPhoneNumber,
      helpdeskEmail: string,
      supportLink: string
    }
  },
  contactHeader: string,
  contactIcon: IStrapiImage
}

export const ContactUsSection: React.FC<ContactUsSectionProps> = ({ contactHeader }) => {
  const data = useStaticQuery<ContactUsSectionProps>(query)
  const config = data.strapi.globalConfig

  return (
    <Section className={styles.contactUsSection}>
      <h1 className={styles.contactHeader}>{contactHeader}</h1>
      <StaticImage alt="phone" src="../../images/contact_icon_phone_transparent.svg" className={styles.icon} quality={100} width={35} />
      <h2 className={styles.contactTitle}>
        Helpdesk: <span className={styles.contactValue}><a href={`tel:${config.localNumber.callableNumber}`}>{config.localNumber.userVisible}</a> or call free <a href={`tel:${config.nationalNumber.callableNumber}`}>{config.nationalNumber.userVisible}</a></span>
      </h2>
      <div className={styles.contactDescription}>
        <p>
          Talk to one of our friendly team,
          <br />
          <b>Monday to Friday 8:00am - 4:00pm</b>
        </p>
      </div>
      <hr className={styles.separator} />
      <StaticImage alt="email" src="../../images/contact_icon_email.svg" className={styles.icon} quality={100} width={35} />
      <h2 className={styles.contactTitle}>
        Email: <span className={styles.contactValue}><a href={`mailto:${config.helpdeskEmail}`}>{config.helpdeskEmail}</a></span>
      </h2>
      <hr className={styles.separator} />
      <StaticImage alt="web" src="../../images/contact_icon_web.svg" className={styles.icon} quality={100} width={35} />
      <h2 className={styles.contactTitle}>
        Online: <span className={styles.contactValue}><a href={`https://${config.supportLink}`} target="_self">{config.supportLink}</a></span>
      </h2>
      <div className={styles.contactDescription}>
        <p>
          Our online support centre for ezlunch and your myKindo account
        </p>
      </div>
      <hr className={styles.separator} />
      <div className={styles.contactForm}>
        <div className={styles.contactTitle}>
          Or you can contact us with this form:
        </div>
        <div className={styles.contactRequired}>
          <p>
            * Required fields
          </p>
        </div>
      </div>
      <DANGEROUS_HtmlAndScript html={Contact} script={CONTACT_FORM_SCRIPT} />
    </Section>
  )
}

const query = graphql`
  query {
    strapi {
      globalConfig {
        localNumber {
          ...phoneNumberFields
        }
        nationalNumber {
          ...phoneNumberFields
        }
        helpdeskEmail
        supportLink
      }
    }
  }
`

export const contactUsSectionFragment = graphql`
  fragment contactUsSectionFragment on Strapi_ComponentSectionContactUs {
    contactHeader
    contactIcon {
      id
      url
      alternativeText
      urlSharp {
        childImageSharp {
          gatsbyImageData(width: 180, quality: 100)
        }
      }
    }
  }
`
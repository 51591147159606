import React from "react"
import { graphql } from "gatsby"
import { IStrapiImage } from "../../model/IStrapiImage"
import Section from "./Section"
import * as styles from "./PicturesAndBody.module.scss"
import { mergeClassNames } from "../../util/util"
import { IPageButton } from "../PageButton"
import { FramedPicture } from "../FramedPicture"
import { ScaleToWindowWidth } from "../ScaleToWindowWidth"
import { StaticImage } from "gatsby-plugin-image"
import { SectionHeadingBodyButtonsColumn } from "../SectionHeadingBodyButtonsColumn"

interface PicturesAndBodyProps {
  heading: string,
  body: string,

  actionButtons: IPageButton[],

  testimonial: {
    heading: string | null,
    source: string,
    testimonial: string
  } | null,

  frontImage: IStrapiImage,
  backImage: IStrapiImage,
  middleImage: IStrapiImage | null
}
export const PicturesAndBody: React.FC<PicturesAndBodyProps> = (props) => {
  const {
    heading, body, actionButtons, testimonial, frontImage,
  } = props
  let { backImage, middleImage  } = props

  if (middleImage !== null && backImage === null) {
    // If middle image is provided but we're in a two picture layout
    [middleImage, backImage] = [backImage, middleImage]
  }

  return (
    <Section className={styles.TwoPicturesAndBody}>
      <ScaleToWindowWidth>
        {(ref, scale) => (
          <div
            className={mergeClassNames(styles.pictures, (middleImage === null) ? undefined : styles.picturesWithMiddlePicture)}
            style={{
              height: ((middleImage === null) ? 400 : 490) * scale + ((testimonial === null) ? 0 : 76)
            }}
          >
            <div
              ref={ref}
              className={styles.picturesContent}
              style={{
                transform: `scale(${scale}, ${scale})`,
                transformOrigin: 'top left'
              }}
            >
              <div className={styles.backImage}>
                <FramedPicture picture={backImage}/>
              </div>
              {(middleImage === null) ? undefined : (
                <div className={styles.middleImage}>
                  <FramedPicture picture={middleImage}/>
                </div>
              )}
              <div className={styles.frontImage}>
                <FramedPicture picture={frontImage}/>
              </div>

              {(middleImage === null) ? (
                <div className={styles.badge}>
                  <StaticImage src="../../images/cupcake_badge_angle.png" alt=""/>
                </div>
              ) : undefined}

              {(testimonial === null) ? undefined : (
                <div className={styles.testimonial} style={{
                  transform: `scale(${1/scale}, ${1/scale})`,
                  transformOrigin: 'top left'
                }}>
                  {(testimonial.heading) ? (
                    <div className={styles.testimonialHeading}>{testimonial.heading}</div>
                  ) : null}
                  <div className={styles.testimonialBody}>{testimonial.testimonial}</div>
                  <div className={styles.testimonialSource}>{testimonial.source}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </ScaleToWindowWidth>

      <SectionHeadingBodyButtonsColumn className={styles.content} heading={heading} body={body} actionButtons={actionButtons}/>
    </Section>
  )
}

export const picturesAndBodyQueryFragment = graphql`
  fragment picturesAndBodyQueryFragment on Strapi_ComponentSectionTwoPicturesAndBody {
    heading
    body
    
    actionButtons {
      ...pageButtonFragment
    }
    
    testimonial {
      heading
      source
      testimonial
    }
    
    frontImage: picture1 {
      url
      alternativeText
      urlSharp {
        childImageSharp {
          gatsbyImageData(width: 315, quality: 90)
        }
      }
    }
    backImage: picture3 {
      url
      alternativeText
      urlSharp {
        childImageSharp {
          gatsbyImageData(width: 250, quality: 90)
        }
      }
    }
    
    middleImage: picture2 {
      url
      alternativeText
      urlSharp {
        childImageSharp {
          gatsbyImageData(width: 350, quality: 90)
        }
      }
    }
  }
`
// extracted by mini-css-extract-plugin
export var SectionNews = "NewsSection-module--SectionNews--3QOh-";
export var blogHeading = "NewsSection-module--blogHeading--1i96X";
export var blogTitle = "NewsSection-module--blogTitle--3LRzI";
export var blogDate = "NewsSection-module--blogDate--y8EA1";
export var blogContent = "NewsSection-module--blogContent--3z-Ug";
export var readMore = "NewsSection-module--readMore--1cZjB";
export var blogImage = "NewsSection-module--blogImage--cV1-G";
export var sliderContent = "NewsSection-module--sliderContent--1nwqD";
export var newsHeader = "NewsSection-module--newsHeader--3NrjD";
export var latestOne = "NewsSection-module--latestOne--d4klg";
export var latestTwo = "NewsSection-module--latestTwo--2plIE";
export var sidebar = "NewsSection-module--sidebar--2QV_0";
export var content = "NewsSection-module--content--1Usz2";
export var blogsContainer = "NewsSection-module--blogsContainer--3-zhH";
export var blogsHeader = "NewsSection-module--blogsHeader--3brmi";
import React from "react"
import { TwoColumnPitchColumnType } from "./TwoColumnPitchSection"
import * as styles from "./TwoColumnPitchSectionColumn.module.scss"

export const TwoColumnPitchColumn: React.FC<TwoColumnPitchColumnType> = ({headline, body, icon}) => {
  return (
    <div className={styles.TwoColumnPitchSectionColumn}>
      <img className={styles.icon} alt={icon.alternativeText} src={icon.urlSharp.staticURL}/>
      <div className={styles.content}>
        <div className={styles.headline}>{headline}</div>
        <div className={styles.body}>{body}</div>
      </div>
    </div>
  )
}

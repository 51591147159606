export interface Size {
  width: number,
  height: number
}

export const scaleSize = (baseSize: Size, targetSize: Size): Size => {
  const largestScaleFactor = Math.max(
    targetSize.width / baseSize.width,
    targetSize.height / baseSize.height
  )

  return {
    width: largestScaleFactor * baseSize.width,
    height: largestScaleFactor * baseSize.height
  }
}

export const maxSize = (...sizes: Size[]): Size => {
  if (sizes.length === 0) {
    throw new Error("At least one size must be provided.")
  }

  const areas = sizes.map((size) => size.width * size.height)
  let bestIndexSoFar = 0
  let bestAreaSoFar: number = areas[0]

  for (let i=1; i< areas.length; i++) {
    if (areas[i] > bestAreaSoFar) {
      bestAreaSoFar = areas[i]
      bestIndexSoFar = i
    }
  }

  return sizes[bestIndexSoFar]
}
import React from "react"
import { graphql } from "gatsby"
import Section from "../Section"
import { TwoColumnPitchColumn } from "./TwoColumnPitchSectionColumn"
import * as styles from "./TwoColumnPitchSection.module.scss"
import { IStrapiImageStaticImage } from "../../../model/IStrapiImageStaticImage"

export interface TwoColumnPitchColumnType {
  headline: string,
  body: string,
  icon: IStrapiImageStaticImage
}

interface TwoColumnPitchSectionProps {
  id: string,
  columns: TwoColumnPitchColumnType[]
}
export const TwoColumnPitchSection: React.FC<TwoColumnPitchSectionProps> = ({columns}) => {
  return (
    <Section className={styles.TwoColumnPitchSection}>
      <TwoColumnPitchColumn {...columns[0]} />
      <TwoColumnPitchColumn {...columns[1]} />
    </Section>
  )
}

export const twoColumnPitchSectionFragment = graphql`
  fragment twoColumnPitchSectionFragment on Strapi_ComponentSectionTwoColumnPitch {
    columns {
      headline
      body
      icon {
        url
        alternativeText
        urlSharp {
          staticURL
        }
      }
    }
  }
`
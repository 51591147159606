import React from "react"
import Section from "./Section"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { IStrapiImage } from "../../model/IStrapiImage"
import { graphql } from "gatsby"
import { SpeechBubble } from "../SpeechBubble"
import * as styles from "./FamiliesSchoolsCafesHeaderSection.module.scss"
import { IStrapiImageStaticImage } from "../../model/IStrapiImageStaticImage"
import { IPageButton, PageButton } from "./../PageButton"

export interface FamiliesSchoolsCafesHeaderProps {
  headerTextTop: string,
  headerTextBottom: string,
  headerTextTopColour: string,
  bubbleTextHighlight: string,
  bubbleTextMain: string,
  rightSideSubHeader: string,
  rightSideContent: string,
  curvedTextImage: IStrapiImageStaticImage,
  leftSideImage: IStrapiImage,
  rightSideImage: IStrapiImage,
  speechBubblePosition: SpeechBubblePosition | null,
  headerButtons: IPageButton[]
}

export interface SpeechBubblePosition {
  top: string,
  bottom: string,
  right: string,
  left: string
}

export const FamiliesSchoolsCafesHeaderSection: React.FC<FamiliesSchoolsCafesHeaderProps> = (
  {rightSideContent, rightSideSubHeader, headerTextBottom, headerTextTopColour, headerTextTop, leftSideImage,
    rightSideImage, curvedTextImage, bubbleTextHighlight, bubbleTextMain, speechBubblePosition, headerButtons}) => {

  return (
    <Section className={styles.sectionContainer}>
      <div className={styles.topHeaderContainer}>
        <StaticImage alt="cloud" src="../../images/fluffy_cloud.svg" className={styles.cloud} quality={100} width={94}/>
        <img className={styles.curvedTextImage} alt={curvedTextImage.alternativeText} src={curvedTextImage.urlSharp.staticURL}/>
        <span style={{ color: `${headerTextTopColour}` }} className={styles.headerTextTop}>{headerTextTop}</span>
      </div>
      <div className={styles.familiesSchoolsCafesHeaderSectionContainer}>
        <div className={styles.sidebar}>
          <GatsbyImage className={styles.iconImage} image={leftSideImage.urlSharp.childImageSharp.gatsbyImageData} alt={leftSideImage.alternativeText}/>
        </div>
        <div className={styles.content}>
          <div className={styles.headerBox}>
            <div className={styles.sidebar}>
              <SpeechBubble
                  size={{
                    width: 100,
                    height: 0
                  }}
                  className={styles.speechBubble}
                  style={{ 
                    zIndex: 2,
                    top: speechBubblePosition?.top,
                    left: speechBubblePosition?.left,
                    right: speechBubblePosition?.right,
                    bottom: speechBubblePosition?.bottom
                  }}
                >
                <span className={styles.bubbleContent}>
                  <span className={styles.bubbleContentHighlight}>{bubbleTextHighlight}</span><br/>
                  <span className={styles.bubbleContentMain}>{bubbleTextMain}</span><br/>
                </span>
              </SpeechBubble>
              <GatsbyImage style={{ zIndex: 1 }} className={styles.rightSideImage} image={rightSideImage.urlSharp.childImageSharp.gatsbyImageData} alt={rightSideImage.alternativeText}/>
            </div>
            <div className={styles.content}>
              <h1>
                <span style={{ color: `${headerTextTopColour}` }} className={styles.headerTextTopMobile}>{headerTextTop}</span>
                <div className={styles.headerTextBottom}>{headerTextBottom}</div>
              </h1>

              <div className={styles.rightSideTextContainer}>
                <div className={styles.rightSideSubHeader}>{rightSideSubHeader}</div>
                <div className={styles.rightSideContent}>{rightSideContent}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.headerButtons}>
        {headerButtons.map((button, index) => (
          <PageButton key={`${index}-${button.id}`} {...button}/>
        ))}
      </div>
    </Section>
  )
}

export default FamiliesSchoolsCafesHeaderSection;

export const familiesSchoolsCafesHeaderSectionFragment = graphql`
  fragment familiesSchoolsCafesHeaderSectionFragment on Strapi_ComponentSectionFamiliesSchoolsCafesHeader {
    headerTextTop
    headerTextBottom
    headerTextTopColour
    bubbleTextHighlight
    bubbleTextMain
    rightSideSubHeader
    rightSideContent
    headerButtons {
      id
      page {
        slug
      }
      type
      text
    }
    curvedTextImage {
      url
      alternativeText
      urlSharp {
        staticURL
      }
    }
    leftSideImage {
      id
      url
      alternativeText
      urlSharp {
        childImageSharp {
          gatsbyImageData(width: 306, quality: 100)
        }
      }
    }
    rightSideImage {
      id
      url
      alternativeText
      urlSharp {
        childImageSharp {
          gatsbyImageData(width: 250, quality: 100)
        }
      }
    }
    speechBubblePosition {
      bottom
      left
      right
      top
    }
  }
`
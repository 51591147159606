import React from "react"
import { graphql, Link } from "gatsby"
import Section from "./Section"
import * as styles from "./FamiliesSchoolsCafesStatisticsOverviewSection.module.scss"
import { SpeechBubble } from "../SpeechBubble"
import { StringParagraph } from "../StringParagraph"
import { getPathForLocale } from "../../util/util"
import { useAppContext } from "../AppContext"
import { StaticImage } from "gatsby-plugin-image"

interface FamiliesSchoolsCafesStatisticsOverviewSectionProps {
  familiesHighlight: string,
  familiesMain: string,
  familiesPage: {
    slug: string | null
  },

  schoolsHighlight: string,
  schoolsMain: string,
  schoolsPage: {
    slug: string
  },

  cafesHighlight: string,
  cafesMain: string,
  cafesPage: {
    slug: string
  }
}
export const FamiliesSchoolsCafesStatisticsOverviewSection: React.FC<FamiliesSchoolsCafesStatisticsOverviewSectionProps> = (props) => {
  const { currentLocale } = useAppContext()

  //const data: FamiliesSchoolsCafesStatisticsOverviewSectionQueryData = useStaticQuery(query)

  return (
    <Section className={styles.FamiliesSchoolsCafesStatisticsOverviewSection}>
      <div className={styles.itemsContainer}>
        <Link to={getPathForLocale(currentLocale, props.familiesPage.slug)}>
          <div
            className={styles.item}
            style={{
              paddingLeft: 80
            }}
          >
            <StaticImage alt="Families" src="../../images/families_icon_shadow.png" className={styles.icon}  placeholder="blurred"/>

            <SpeechBubble
              className={styles.speechBubble}
              style={{
                top: 0,
                left: 0,
                overflow: "shown"
              }}
              size={{
                width: 100,
                height: 70
              }}
            >
            <span className={styles.bubbleContent}>
              <span className={styles.bubbleContentHighlight}><StringParagraph para={props.familiesHighlight}/></span><br/>
              <span className={styles.bubbleContentMain}><StringParagraph para={props.familiesMain}/></span>
            </span>
            </SpeechBubble>
          </div>
        </Link>

        <Link to={getPathForLocale(currentLocale, props.schoolsPage.slug)}>
          <div
            className={styles.item}
            style={{
              paddingLeft: 110
            }}
          >
            <StaticImage alt="Schools" src="../../images/schools_icon_shadow.png" className={styles.icon} quality={100} width={261} placeholder="blurred"/>

            <SpeechBubble
              className={styles.speechBubble}
              style={{
                top: 0,
                left: 0,
                overflow: "shown"
              }}
              size={{
                width: 100,
                height: 70
              }}
            >
            <span className={styles.bubbleContent}>
              <span className={styles.bubbleContentHighlight}><StringParagraph para={props.schoolsHighlight}/></span><br/>
              <span className={styles.bubbleContentMain}><StringParagraph para={props.schoolsMain}/></span>
            </span>
            </SpeechBubble>
          </div>
        </Link>

        <Link to={getPathForLocale(currentLocale, props.cafesPage.slug)}>
          <div
            className={styles.item}
            style={{
              paddingRight: 50
            }}
          >
            <StaticImage alt="Cafes" src="../../images/cafes_icon_shadow.png" className={styles.icon} quality={100} width={261} placeholder="blurred"/>

            <SpeechBubble
              className={styles.speechBubble}
              style={{
                top: 0,
                right: 0,
                overflow: "shown"
              }}
              size={{
                width: 100,
                height: 0
              }}
            >
            <span className={styles.bubbleContent}>
              <span className={styles.bubbleContentHighlight}><StringParagraph para={props.cafesHighlight}/></span><br/>
              <span className={styles.bubbleContentMain}><StringParagraph para={props.cafesMain}/></span>
            </span>
            </SpeechBubble>
          </div>
        </Link>
      </div>
    </Section>
  )
}

export const familiesSchoolsCafesStatisticsOverviewFragment = graphql`
  fragment familiesSchoolsCafesStatisticsOverviewFragment on Strapi_ComponentSectionFamiliesSchoolsCafesStatisticsOverview {
    familiesHighlight
    familiesMain
    familiesPage {
      slug
    }
    
    schoolsHighlight
    schoolsMain
    schoolsPage {
      slug
    }
    
    cafesHighlight
    cafesMain
    cafesPage {
      slug
    }
  }
`
import React from "react"
import { graphql } from "gatsby"
import { IStrapiImage } from "./../../model/IStrapiImage"
import * as styles from "./FourColumnSection.module.scss"
import Section from "./Section"
import { GatsbyImage } from "gatsby-plugin-image"
import { IPageButton, PageButton } from "../PageButton"

export interface FourColumnCard {
    cardHeading: string,
    cardIcon: IStrapiImage,
    cardDescription: string,
    cardSmallText: string,
    id: string
}

interface FourColumnSectionProps {
  id: string,
  fourColumnHeading: string,
  fourColumnHeadingSmallText: string | null,
  fourColumnSubHeading: string,
  fourColumnHeadingColour: string,
  fourColumnHeaderPhoto1: IStrapiImage,
  fourColumnHeaderPhoto2: IStrapiImage,
  card: FourColumnCard[],
  fourColumnButton: IPageButton[]
}

export const FourColumnSection: React.FC<FourColumnSectionProps> = ({fourColumnHeading, fourColumnSubHeading, fourColumnHeadingColour, fourColumnHeaderPhoto1, fourColumnHeaderPhoto2, card, fourColumnButton, fourColumnHeadingSmallText}) => {
  return (
      <Section>
          <div className={styles.headingSection}>
              <div className={styles.columnHeader}>
                  <h2 style={{ color: `${fourColumnHeadingColour}`}} className={styles.headingSectionHeaderText}>{fourColumnHeading}{fourColumnHeadingSmallText ? <span className={styles.fourColumnHeadingSmallText}>{fourColumnHeadingSmallText}</span>: undefined }</h2>
                  <div className={styles.headingSectionSubHeadingText}>{fourColumnSubHeading}</div>
              </div>
              <div className={styles.columnImage}>
                  <GatsbyImage image={fourColumnHeaderPhoto1.urlSharp.childImageSharp.gatsbyImageData} alt={fourColumnHeaderPhoto1.alternativeText}/>
              </div>
              <div className={styles.columnPlus}>+</div>
              <div className={styles.columnImage}>
                  <GatsbyImage image={fourColumnHeaderPhoto2.urlSharp.childImageSharp.gatsbyImageData} alt={fourColumnHeaderPhoto2.alternativeText}/>
              </div>
          </div>
          <div className={styles.cardSection}>
          {card.map((c) => (
                  <CardContent
                  key={`card-${c.id}`}
                  {...c}
                  />
              ))}
          </div>
          <div className={styles.fourColumnButton}>
              {fourColumnButton.map((button, index) => (
                  <PageButton key={`${index}-${button.id}`} {...button}/>
              ))}
          </div>
      </Section>
  )
}

const CardContent: React.FC<FourColumnCard> = ({cardHeading, cardIcon, cardDescription, cardSmallText}) => {
  return (
    <div className={styles.cardColumn}>
        <div className={styles.cardBox}>
            <div className={styles.cardHeading}>{cardHeading}</div>
            <div className={styles.cardContainer}>
              <GatsbyImage image={cardIcon.urlSharp.childImageSharp.gatsbyImageData} alt={cardIcon.alternativeText}/>
              {cardSmallText ? <div className={styles.cardSmallText}>{cardSmallText}</div> : ''}
            </div>
        </div>
        <div className={styles.cardDescription}>{cardDescription}</div>
    </div>
  )
}

export const fourColumnSectionFragment = graphql`
  fragment fourColumnSectionFragment on Strapi_ComponentSectionFourColumnCard {
    id
    fourColumnHeading
    fourColumnHeadingSmallText
    fourColumnSubHeading
    fourColumnHeadingColour
    fourColumnHeaderPhoto1 {
        url
        urlSharp {
          childImageSharp {
            gatsbyImageData(width: 400, quality: 100)
          }
        }
    }
    fourColumnHeaderPhoto2 {
        url
        urlSharp {
          childImageSharp {
            gatsbyImageData(width: 400, quality: 100)
          }
        }
    }
    fourColumnButton {
        id
        page {
          slug
        }
        type
        text
    }
    card {
      cardHeading
      id
      cardDescription
      cardSmallText
      cardIcon {
        url
        urlSharp {
          childImageSharp {
            gatsbyImageData(width: 100, quality: 100)
          }
        }
      }
    }
  }
`
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { IStrapiImage } from "../../model/IStrapiImage"
import * as styles from "./ThreeColumnCaseShareSection.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { IPageButton, PageButton } from "../PageButton"
import { SpeechBubble } from "../SpeechBubble"
import { StringParagraph } from "../StringParagraph"
import { IMetadata } from "../../model/IMetadata"
import { SocialIcons } from "../social/SocialIcons"

interface ThreeColumnCaseShareSectionProps {
  bubbleText: string,
  threeColumnHeader: string,
  threeColumnSubHeader: string,
  threeColumnHeaderColour: string,
  threeColumnImage: IStrapiImage,
  findOutBtn: IPageButton[],
  displaySocialIcons: boolean
}

interface ConnectionSectionData {
  strapi: {
    globalConfig: {
      Metadata: IMetadata
    }
  }
}

export const ThreeColumnCaseShareSection: React.FC<ThreeColumnCaseShareSectionProps> = ({displaySocialIcons, bubbleText, threeColumnHeader, threeColumnSubHeader, threeColumnHeaderColour, threeColumnImage, findOutBtn}) => {
  const data = useStaticQuery<ConnectionSectionData>(connectSectionQuery)
  const {Metadata: defaultMetadata} = data.strapi.globalConfig

  return (
    <div className={styles.threeColumnCaseShareSection}>
      <div className={styles.left}>
        <SpeechBubble
          className={styles.speechBubble}
          style={{
            top: 0,
            left: 0,
            overflow: "shown"
          }}
          size={{
            width: 223,
            height: 155
          }}
          >
          <span className={styles.bubbleContent}>
            <span className={styles.bubbleContentHighlight}><StringParagraph para={bubbleText}/></span><br/>
          </span>
        </SpeechBubble>
      </div>
      <div className={styles.middle}>
        <GatsbyImage alt={threeColumnImage.alternativeText} image={threeColumnImage.urlSharp.childImageSharp.gatsbyImageData} />
      </div>
      <div className={styles.right}>
        <h2 style={{ color: `${threeColumnHeaderColour}`}} className={styles.threeColumnHeader}>{threeColumnHeader}</h2>
        <div className={styles.threeColumnSubHeader}>{threeColumnSubHeader}</div>
        <div className={styles.findOutBtn}>
          {findOutBtn.map((button, index) => (
            <PageButton key={`${index}-${findOutBtn.id}`} {...button}/>
          ))}
          {displaySocialIcons ? 
            <div className={styles.socialSection}>
              <SocialIcons pageSeo={defaultMetadata} />
            </div>
          : undefined}
        </div>
      </div>
    </div>
  )
}

export const threeColumnCaseShareSectionFragment = graphql`
  fragment threeColumnCaseShareSectionFragment on Strapi_ComponentSectionThreeColumnCaseShare {
    bubbleText
    threeColumnHeader
    threeColumnSubHeader
    threeColumnHeaderColour
    displaySocialIcons
    findOutBtn {
      id
      page {
        slug
      }
      type
      text
    }
    threeColumnImage {
      url
      urlSharp {
        childImageSharp {
          gatsbyImageData(width: 204, quality: 100)
        }
      }
    }
  }
`

const connectSectionQuery = graphql`
query {
  strapi {
    globalConfig {
      titleSuffix
      Metadata {
          metaTitle
          metaDescription
          shareImage {
            url
            urlSharp {
              staticURL
            }
          }
        }
    }
  }
}
`
import { graphql } from "gatsby"
import React from "react"
import * as styles from "./TestimonialsSection.module.scss"

interface TestimonialType {
  id: string,
  heading: string | null,
  testimonial: string,
  source: string
}
interface TestimonialsSectionProps {
  heading: string,
  testimonials: TestimonialType[]
}
export const TestimonialsSection: React.FC<TestimonialsSectionProps> = ({heading, testimonials}) => {
  return (
    <div className={styles.TestimonialsSection}>
      <h2 className={styles.heading}>{heading}</h2>
      <div className={styles.testimonialsContainer}>
        {testimonials.map((testimonial) => (
          <Testimonial
            key={`Testimonial-${testimonial.id}`}
            {...testimonial}
          />
        ))}
      </div>
    </div>
  )
}

const Testimonial: React.FC<TestimonialType> = ({heading, testimonial, source}) => {
  return (
    <div className={styles.Testimonial}>
      {(heading) ? (
        <h3 className={styles.testimonialHeading}>{heading}</h3>
      ) : null}
      <div>{testimonial}</div>
      <div className={styles.testimonialSource}>{source}</div>
    </div>
  )
}

export const testimonialsSectionFragment = graphql`
  fragment testimonialsSectionFragment on Strapi_ComponentSectionTestimonials {
    heading
    testimonials {
      id
      heading
      testimonial
      source
    }
  }
`
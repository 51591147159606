import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { IStrapiImage } from "../../model/IStrapiImage"
import * as styles from "./TwoColumnTalkSection.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import Section from "./Section"
import { IPhoneNumber } from "../../model/IPhoneNumber"
import {RichText} from "../RichText";

interface TwoColumnTalkSectionProps {
  talkDescription: string,
  talkImage: IStrapiImage
}
interface TwoColumnTalkSectionData {
  localNumber: IPhoneNumber,
  nationalNumber: IPhoneNumber
}
export const TwoColumnTalkSection: React.FC<TwoColumnTalkSectionProps> = ({talkDescription, talkImage}) => {
  const data = useStaticQuery<{ strapi: {globalConfig: TwoColumnTalkSectionData} }>(query)
  const config = data.strapi.globalConfig

  return (
    <Section>
      <div className={styles.twoColumnTalkSectionContainer}>
        <div className={styles.sidebar}>
          <div>
            <GatsbyImage image={talkImage.urlSharp.childImageSharp.gatsbyImageData} alt={talkImage.alternativeText}/>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.talkContact}>
            <a href={`tel:${config.localNumber.callableNumber}`}>{config.localNumber.userVisible}</a> or call free <a href={`tel:${config.nationalNumber.callableNumber}`}>{config.nationalNumber.userVisible}</a>
          </div>
          <h2 className={styles.talkDescriptionHeading}>
            <RichText className={styles.talkDescriptionHeadingContent} text={talkDescription}/>
          </h2>
        </div>
      </div>
    </Section>
  )
}

const query = graphql`
  query {
    strapi {
      globalConfig {
        localNumber {
          ...phoneNumberFields
        }
        nationalNumber {
          ...phoneNumberFields
        }
      }
    }
  }
`

export const twoColumnTalkSectionFragment = graphql`
  fragment twoColumnTalkSectionFragment on Strapi_ComponentSectionTwoColumnTalk {
    talkDescription: talkDesciption
    talkImage {
      id
      url
      alternativeText
      urlSharp {
        childImageSharp {
          gatsbyImageData(width: 200, quality: 100)
        }
      }
    }
  }
`
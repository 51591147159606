// extracted by mini-css-extract-plugin
export var familiesSchoolsCafesHeaderSectionContainer = "FamiliesSchoolsCafesHeaderSection-module--familiesSchoolsCafesHeaderSectionContainer--1zmCS";
export var sidebar = "FamiliesSchoolsCafesHeaderSection-module--sidebar--3Ciiu";
export var content = "FamiliesSchoolsCafesHeaderSection-module--content--1FC0-";
export var curvedTextImage = "FamiliesSchoolsCafesHeaderSection-module--curvedTextImage--1_Bvn";
export var headerButtons = "FamiliesSchoolsCafesHeaderSection-module--headerButtons--2uToE";
export var topHeaderContainer = "FamiliesSchoolsCafesHeaderSection-module--topHeaderContainer--3yhvf";
export var sectionContainer = "FamiliesSchoolsCafesHeaderSection-module--sectionContainer--1g8zT";
export var headerBox = "FamiliesSchoolsCafesHeaderSection-module--headerBox--2_oS-";
export var bubbleContent = "FamiliesSchoolsCafesHeaderSection-module--bubbleContent--3tG85";
export var bubbleContentHighlight = "FamiliesSchoolsCafesHeaderSection-module--bubbleContentHighlight--YErVv";
export var bubbleContentMain = "FamiliesSchoolsCafesHeaderSection-module--bubbleContentMain--2qkW-";
export var headerTextBottom = "FamiliesSchoolsCafesHeaderSection-module--headerTextBottom--2BtEV";
export var headerTextTop = "FamiliesSchoolsCafesHeaderSection-module--headerTextTop--1aDNY";
export var cloud = "FamiliesSchoolsCafesHeaderSection-module--cloud--LlVVU";
export var rightSideContent = "FamiliesSchoolsCafesHeaderSection-module--rightSideContent--k5h3G";
export var rightSideSubHeader = "FamiliesSchoolsCafesHeaderSection-module--rightSideSubHeader--2WQXl";
export var rightSideTextContainer = "FamiliesSchoolsCafesHeaderSection-module--rightSideTextContainer--2rCjq";
export var rightSideImage = "FamiliesSchoolsCafesHeaderSection-module--rightSideImage--1nuXM";
export var headerTextTopMobile = "FamiliesSchoolsCafesHeaderSection-module--headerTextTopMobile--3bwUm";
export var iconImage = "FamiliesSchoolsCafesHeaderSection-module--iconImage--1qmz7";
export var speechBubble = "FamiliesSchoolsCafesHeaderSection-module--speechBubble--2VZXY";